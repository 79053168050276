import * as React from "react";
import "./cardTopLabel.style.css";

interface IProps {
  children: any;
}

const CardTopLabel = (props: IProps) => {
  const { children } = props;

  return <div className="cardTopLabel">{children}</div>;
};

export default CardTopLabel;
