import * as React from "react";
import customGA from "../../util/customGA";
import "./whatsappChat.style.css";

/*
const colors = new Map([
  ["blue", "-blue"],
  ["grey", "-grey"],
  ["pink", "-pink"],
  ["yellow", "-yellow"],
  ["orange", "-orange"],
  ["purple", "-purple"],
  ["white", "-white"],
]);
	// @ts-ignore
<iframe
	width="250"
	height="50"
	src="https://cdn.smooch.io/message-us/index.html?channel=whatsapp&color=green&size=standard&radius=4px&label=Message
	us on WhatsApp&number=59995270872"
></iframe>
*/

interface IProps {
  className?: string;
}

const WhatsappChat = (props: IProps) => {
  const { className } = props;

  function goToWhatsapp() {
    customGA("event", "click_whatsapp");
    window.open("https://wa.me/59995270872", "_blank", "noopener noreferrer");
  }

  return (
    <div
      className={`whatsappchat ${className ? className : ""}`}
      onClick={() => goToWhatsapp()}
    >
      <img src="/images/whatsapp.png" alt="WhatsApp" width="250" height="50" />
    </div>
  );
};

export default WhatsappChat;
