import moment from "moment";
import "moment-timezone";
import * as React from "react";
import { useTranslation } from "react-i18next";
import "./cardDate.style.css";

moment.tz.load({
  version: "2018g",
  zones: ["America/Curacao|LMT -0430 AST|4z.L 4u 40|012|-2kV7o.d 28KLS.d|15e4"],
  links: [
    "America/Curacao|America/Aruba",
    "America/Curacao|America/Kralendijk",
    "America/Curacao|America/Lower_Princes",
  ],
});

interface IProps {
  date: string;
}

const CardDate = (props: IProps) => {
  const { date } = props;
  const { i18n } = useTranslation();

  return (
    <div className="cardDate">
      <div className="cardDate__month">
        <h6>
          {moment(date)
            .tz("America/Curacao")
            .locale(i18n.language)
            .format("MMM")}
        </h6>
        <h5>
          {moment(date).tz("America/Curacao").locale(i18n.language).format("D")}
        </h5>
      </div>
      <div className="cardDate__hour">
        <h4>
          {moment(date).tz("America/Curacao").locale(i18n.language).format("h")}
          {nearestMinutes(
            moment(date).tz("America/Curacao").locale(i18n.language)
          ).format("mm") !== "00" &&
            nearestMinutes(
              moment(date).tz("America/Curacao").locale(i18n.language)
            ).format(".mm")}
        </h4>
        <h5 className="h6">
          {moment(date).tz("America/Curacao").locale(i18n.language).format("A")}
        </h5>
      </div>
    </div>
  );
};

function nearestMinutes(someMoment: any) {
  const roundedMinutes = Math.round(someMoment.clone().minute() / 15) * 15;
  return someMoment.clone().minute(roundedMinutes).second(0);
}

export default CardDate;
