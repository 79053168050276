import * as React from "react";
import { useTranslation } from "react-i18next";
import { NavLink, NavLinkProps } from "react-router-dom";
import { AppContext } from "../../context";

type ILocationNavigation = NavLinkProps & {
  includesLocal?: boolean;
  baseLink?: string;
};

const LocaleNavigationLink = (props: ILocationNavigation) => {
  const { i18n } = useTranslation();
  const { subSelected, mainSelected } = React.useContext(AppContext);
  const link = props.to
    ? props.to.toString().startsWith("/")
      ? props.to.toString().substring(1)
      : props.to.toString()
    : "";
  const slugParts = link.split("/");
  const slug = slugParts[slugParts.length - 1];

  const propsCopy = { ...props };
  delete propsCopy.includesLocal;
  delete propsCopy.baseLink;

  return (
    <NavLink
      {...propsCopy}
      to={`${props.includesLocal ? "" : `/${i18n.language}`}/${link}`}
      className={`${props.className ? props.className : ""} ${
        (mainSelected === slug && props.className !== "submenu") ||
        (subSelected === slug &&
          mainSelected === props.baseLink &&
          props.className === "submenu")
          ? "selected"
          : ""
      }`}
      onClick={props.onClick}
    >
      {props.children}
    </NavLink>
  );
};

export default LocaleNavigationLink;

interface ILocaleNavigationWrapper {
  children: (link: string) => any;
  to: string;
}

export const LocaleNavigationWrapper = (props: ILocaleNavigationWrapper) => {
  const { i18n } = useTranslation();
  const link = props.to
    ? props.to.toString().startsWith("/")
      ? props.to.toString().substring(1)
      : props.to.toString()
    : "";
  return props.children(`/${i18n.language}/${link}`);
};
