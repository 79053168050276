import * as React from "react";
import "./searchInput.style.css";
import { SearchSVG } from "../../SVG";

interface IProps {
  className?: string;
  value?: string;
  placeholder?: string;
  title?: string;
  icon?: boolean | string;
  errorMessage?: string | boolean;
  onChange?: any;
  onKeyDown?: any;
  onSubmit?: any;
  name: string;
  showClose?: boolean;
  showGlass?: boolean;
  onClose?: () => void;
  focus?: boolean;
}

const SearchInput = (props: IProps) => {
  const {
    className = "",
    value = "",
    placeholder = "",
    // title = "",
    // errorMessage = false,
    onChange,
    onKeyDown,
    onSubmit,
    name,
    // icon = false,
    focus = false,
    showClose,
    showGlass,
    onClose,
  } = props;
  return (
    <div className={`searchInput ${className}`}>
      <input
        className={`searchInput__input`}
        type="text"
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        name={name}
        autoFocus={focus}
      />

      {(value || name === "nav search") && showClose !== false && (
        <div role="button" className="searchInput__close" onClick={onClose} />
      )}

      {value && showGlass && (
        <SearchSVG className="searchInput__glass" onClick={onSubmit} />
      )}
    </div>
  );
};

export default SearchInput;
