import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import Loadable from "react-loadable";
import { Redirect, RouteComponentProps, withRouter } from "react-router";
import Container from "../../components/Container";
import Loader from "../../components/Loader";
import { AppContext, IAppInterface } from "../../context";
import "./notFound.style.css";

const AsyncPage = Loadable({
  loader: () => import("../Page"),
  loading: Loader,
});

interface IProps
  extends RouteComponentProps<{ slug: string; locale: string }>,
    WithTranslation {}

const NotFound = (props: IProps) => (
  <Container className="notFound__container">
    <AppContext.Consumer>
      {({ siteConfig }: IAppInterface) => {
        if (!siteConfig) {
          return null;
        }

        if (!siteConfig || !siteConfig.fields.notFoundPage) {
          return <Redirect to="/" />;
        }

        const slug = siteConfig.fields.notFoundPage.fields.slug as string;

        return (
          <AsyncPage
            {...props}
            match={{ ...props.match, params: { ...props.match.params, slug } }}
          />
        );
      }}
    </AppContext.Consumer>
  </Container>
);

export default withTranslation()(withRouter(NotFound));
