import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const getBrowserLanguage = () => {
  if (window.navigator.language) {
    const language = window.navigator.language;
    return language.length > 2 ? language.substring(0, 2) : language;
  }
  return "en";
};

export const resources = {
  en: {
    translations: {
      viewFilters: "View filters",
      filter: "Filters",
      viewAll: "View all",
      youMayAlsoLikeTitle: "You may also like",
      youMayAlsoLikeSubtitle:
        "Here are some other experiences in Curaçao we think you would like",
      showMap: "Show Map",
      hideMap: "Hide Map",
      reserveNow: "Reserve now",
      moreInformation: "More information",
      more: "More",
      readMore: "Read more",
      loadMore: "Load more",
      learnMore: "Learn more",
      pastEvent: "Past event",
      noEventsFound: "No results found",
      menu: "Menu",
      shareOn: "Share on",
      facebook: "Facebook",
      whatsapp: "WhatsApp",
      twitter: "Twitter",
      email: "Email",
      copyLink: "Copy link",
      messenger: "Messenger",
      sms: "SMS",
      yourFavourites: "My favorites",
      favouritesText1:
        "It looks like you haven’t saved any of your favorite places to stay yet.",
      favouritesText2:
        "Whenever you want to save something for later, make sure to click on the ",
      emptyImage: "Empty image",
      share: "Share",
      shareLink: "Share link",
      activities: "Activities",
      accommodation: "Accommodation",
      events: "Events",
      accommodations: "Accommodations",
      thingsToDo: "Things to do",
      whereToStay: "Where To Stay",
      visitorInformationCategories: "Visitor information categories",
      tripAdvisorTravelerRating: "TripAdvisor traveler rating",
      reviews: "reviews",
      iAccept: "I accept",
      confirmSelection: "Confirm selection",
      giveConsent: "Give consent",
      withdrawConsent: "Withdraw consent",
      searchSomething: "Search",
      searchResults: "search results",
      tripAdvisorReviews: "{{value}} TripAdvisor Reviews",
      spamProtectionInputLabel: "Don't fill out this field if you're human",
      seeMore: "See more {{value}}",
      itineraries: "Itineraries",
      itinerariesSlug: "itineraries",
      back: "Back",
      toTop: "Top",
      metaTitleFragment: " | Curacao, the Caribbean Getaway",
      DAY: "DAY",
      DAYS: "DAYS",
      viewFullItinerary: "View Full Itinerary",
      visitedCuracaoBefore: "I have visited Curaçao before",
      travelUpdates: "Travel Advisory",
      chooseDates: "Choose dates",
      Services: "Services",
      article: "Article", // search type
      activity: "Activity", // search type
      category: "Category", // search type
      questions: "FAQ", // search type
      event: "Event", // search type
      itinerary: "Itinerary", // search type
      page: "Page", // search type
      visitWebsite: "Visit website",
      followUs: "Follow us",
    },
  },
  nl: {
    translations: {
      viewFilters: "Weergavefilters",
      filter: "Filters",
      viewAll: "Alles bekijken",
      youMayAlsoLikeTitle: "Misschien hou je ook wel van",
      youMayAlsoLikeSubtitle:
        "Hier zijn enkele andere activiteiten op Curaçao die je misschien wel bevallen.",
      showMap: "Kaart weergeven",
      hideMap: "Kaart verbergen",
      reserveNow: "Nu reserveren",
      moreInformation: "meer informatie",
      more: "Meer",
      readMore: "Lees verder",
      loadMore: "Laad meer",
      learnMore: "Meer informatie",
      pastEvent: "Eerder evenement",
      noEventsFound: "Geen resultaten gevonden",
      menu: "Menu",
      shareOn: "Delen op",
      facebook: "Facebook",
      whatsapp: "WhatsApp",
      twitter: "Twitter",
      email: "E-mail",
      copyLink: "Link kopiëren",
      messenger: "Messenger",
      sms: "SMS",
      yourFavourites: "Mijn favorieten",
      favouritesText1:
        "Zo te zien heb je nog geen favoriete plekken opgeslagen.",
      favouritesText2:
        "Wanneer je iets op wil slaan om later nog eens te bekijken, klik op het ",
      emptyImage: "Leeg plaatje",
      share: "Delen",
      shareLink: "Link delen",
      activities: "Activiteiten",
      accommodation: "Accommodatie",
      events: "Evenementen",
      accommodations: "Accommodaties",
      thingsToDo: "Dingen om te doen",
      whereToStay: "Waar te verblijven",
      visitorInformationCategories: "Bezoekersinformatie categorieën",
      tripAdvisorTravelerRating: "TripAdvisor Reiziger Waardering",
      reviews: "beoordelingen",
      iAccept: "Ik accepteer",
      confirmSelection: "Bevestig selectie",
      giveConsent: "Geef toestemming",
      withdrawConsent: "Trek toestemming in",
      searchSomething: "Zoeken",
      searchResults: "zoekresultaten",
      tripAdvisorReviews: "{{value}} TripAdvisor Beoordelingen",
      spamProtectionInputLabel: "Vul dit niet in als je een mens bent",
      seeMore: "Bekijk meer {{value}}",
      itineraries: "Reisplannen",
      itinerariesSlug: "reisplannen",
      back: "Terug",
      toTop: "Naar boven",
      metaTitleFragment: " | Curacao, de Caribische Zonvakantie",
      DAY: "DAG",
      DAYS: "DAGEN",
      viewFullItinerary: "Bekijk Volledig Reisplan",
      visitedCuracaoBefore: "Ik heb Curaçao al een keer bezocht",
      travelUpdates: "Reisadvies",
      chooseDates: "Kies datums",
      Services: "Voorzieningen",
      article: "Artikel", // search type
      activity: "Activiteit", // search type
      category: "Categorie", // search type
      questions: "FAQ", // search type
      event: "Evenement", // search type
      itinerary: "Reisplan", // search type
      page: "Pagina", // search type
      visitWebsite: "Bezoek website",
      followUs: "Volg ons",
    },
  },
  fr: {
    translations: {
      viewFilters: "Voir les filtres",
      filter: "Filtres",
      viewAll: "Tout voir",
      youMayAlsoLikeTitle: "Vous pourriez aussi aimer",
      youMayAlsoLikeSubtitle:
        "Voici d’autres activités sur l’île de Curaçao que vous pourriez aimer.",
      showMap: "Voir la carte",
      hideMap: "Fermer la carte",
      reserveNow: "Réserver maintenant",
      moreInformation: "Plus d'information",
      more: "Plus",
      readMore: "En savoir plus",
      loadMore: "Charger plus",
      learnMore: "En savoir plus",
      pastEvent: "Évènements passés",
      noEventsFound: "Aucun résultat trouvé",
      menu: "Menu",
      shareOn: "Partager",
      facebook: "Facebook",
      whatsapp: "WhatsApp",
      twitter: "Twitter",
      email: "Courriel",
      copyLink: "Copier le lien",
      messenger: "Messenger",
      sms: "SMS",
      yourFavourites: "Mes favoris",
      favouritesText1:
        "It looks like you haven’t saved any of your favorite places to stay yet.",
      favouritesText2:
        "Chaque fois que vous souhaitez enregistrer quelque chose pour plus tard, assurez-vous de cliquer sur le ",
      emptyImage: "Empty image",
      share: "Partager",
      shareLink: "Lien de partage",
      activities: "Activités",
      accommodation: "Hébergement",
      events: "Évènements",
      accommodations: "Hébergement",
      thingsToDo: "Les choses à faire",
      whereToStay: "Où dormir",
      visitorInformationCategories: "Visitor information categories",
      tripAdvisorTravelerRating: "TripAdvisor traveler rating",
      reviews: "reviews",
      iAccept: "I accept",
      confirmSelection: "Confirm selection",
      giveConsent: "Give consent",
      withdrawConsent: "Withdraw consent",
      searchSomething: "Chercher",
      searchResults: "résultats dans la recherche",
      tripAdvisorReviews: "{{value}} TripAdvisor Reviews",
      spamProtectionInputLabel: "Don't fill out this field if you're human",
      seeMore: "See more {{value}}",
      itineraries: "Bons plans",
      itinerariesSlug: "bons-plans",
      back: "Retour",
      toTop: "En haut",
      metaTitleFragment: " | Curacao, Vacances dans les Caraïbes",
      DAY: "JOUR",
      DAYS: "JOURS",
      viewFullItinerary: "Voir Itinéraire Complet",
      visitedCuracaoBefore: "J'ai déjà visité Curaçao",
      travelUpdates: "Avis de Voyage",
      chooseDates: "Choisir les dates",
      Services: "Les services",
      article: "Article", // search type
      activity: "Activité", // search type
      category: "Catégorie", // search type
      questions: "FAQ", // search type
      event: "Événement", // search type
      itinerary: "Itinéraire", // search type
      page: "Page", // search type
      visitWebsite: "Visitez le site Web",
      followUs: "Suivez-nous",
    },
  },
  es: {
    translations: {
      viewFilters: "Ver filtros",
      filter: "Filtros",
      viewAll: "Ver todo",
      youMayAlsoLikeTitle: "También le puede interesar",
      youMayAlsoLikeSubtitle:
        "Aquí hay otras experiencias en Curazao que creemos que le gustarían.",
      showMap: "Mostrar mapa",
      hideMap: "Ocultar mapa",
      reserveNow: "Reserve ahora",
      moreInformation: "Más información",
      more: "Más",
      readMore: "Leer más",
      loadMore: "Cargar más",
      learnMore: "Más información",
      pastEvent: "Evento pasado",
      noEventsFound: "No se encontraron resultados",
      menu: "Menú",
      shareOn: "Compartir en",
      facebook: "Facebook",
      whatsapp: "WhatsApp",
      twitter: "Twitter",
      email: "Correo electrónico",
      copyLink: "Copiar enlace",
      messenger: "Messenger",
      sms: "SMS",
      yourFavourites: "Mis favoritos",
      favouritesText1: "Parece que no has guardado ningún lugar favorito aún.",
      favouritesText2:
        "Cuando quiera guardar algo para más tarde, asegúrese de hacer clic en el ",
      emptyImage: "Empty image",
      share: "Compartir",
      shareLink: "Enlace de compartir",
      activities: "Actividades",
      accommodation: "Alojamiento",
      events: "Eventos",
      accommodations: "Alojamiento",
      thingsToDo: "Cosas que hacer",
      whereToStay: "¿Dónde quedarse?",
      visitorInformationCategories: "Información para el visitante",
      tripAdvisorTravelerRating: "Puntuación de TripAdvisor",
      reviews: "comentarios",
      iAccept: "Acepto",
      confirmSelection: "Confirmar selección",
      giveConsent: "Autorizar",
      withdrawConsent: "Retirar autorización",
      searchSomething: "Buscar",
      searchResults: "resultados de la búsqueda",
      tripAdvisorReviews: "{{value}} Comentarios de TripAdvisor",
      spamProtectionInputLabel: "No llenes este campo si eres humano",
      seeMore: "Ver más {{value}}",
      itineraries: "Itinerarios",
      itinerariesSlug: "itinerarios",
      back: "Regresa",
      toTop: "Top",
      metaTitleFragment: " | Curacao, la Escapada al Caribe",
      DAY: "DIA",
      DAYS: "DIAS",
      viewFullItinerary: "Ver Itinerario Completo",
      visitedCuracaoBefore: "He visitado Curazao antes",
      travelUpdates: "Aviso de Viaje",
      chooseDates: "Elige fechas",
      Services: "Servicios",
      article: "Artículo", // search type
      activity: "Actividad", // search type
      category: "Categoría", // search type
      questions: "Preguntas más frecuentes", // search type
      event: "Evento", // search type
      itinerary: "Itinerario", // search type
      page: "Página", // search type
      visitWebsite: "Visita página web",
      followUs: "Síganos",
    },
  },
  de: {
    translations: {
      viewFilters: "Filter anzeigen",
      filter: "Filter",
      viewAll: "Alle anzeigen",
      youMayAlsoLikeTitle: "Das könnte Sie auch interessieren",
      youMayAlsoLikeSubtitle:
        "Hier einige weitere Angebote auf Curacao, die Sie auch interessieren könnten.",
      showMap: "Karte anzeigen",
      hideMap: "Karte ausblenden ",
      reserveNow: "Jetzt buchen",
      moreInformation: "Mehr Informationen",
      more: "Mehr",
      readMore: "Weiterlesen",
      loadMore: "Mehr laden",
      learnMore: "Mehr erfahren",
      pastEvent: "Letzte Veranstaltung",
      noEventsFound: "Keine Ergebnisse gefunden",
      menu: "Menü",
      shareOn: "Teilen über",
      facebook: "Facebook",
      whatsapp: "WhatsApp",
      twitter: "Twitter",
      email: "E-Mail",
      copyLink: "Link kopieren",
      messenger: "Messenger",
      sms: "SMS",
      yourFavourites: "Meine Favoriten",
      favouritesText1:
        "Es schaut so aus, als ob Sie noch keine Lieblingsorte in Curaçao gespeichert haben.",
      favouritesText2:
        "Wenn Sie etwas für später speichern möchten, klicken Sie auf das ",
      emptyImage: "Empty image",
      share: "Teilen",
      shareLink: "Link teilen",
      activities: "Aktivitäten",
      accommodation: "Unterkunft",
      events: "Events",
      accommodations: "Unterkünfte",
      thingsToDo: "To-do-Liste ",
      whereToStay: "Unterkunft",
      visitorInformationCategories: "Besucherinformationen",
      tripAdvisorTravelerRating: "TripAdvisor Reisende Prädikat",
      reviews: "Reisebewertungen",
      iAccept: "Ich stimme zu",
      confirmSelection: "Auswahl bestätigen",
      giveConsent: "Zustimmen",
      withdrawConsent: "Zustimmung zurückziehen",
      searchSomething: "Suche",
      searchResults: "Suchergebnisse",
      tripAdvisorReviews: "{{value}} TripAdvisor Bewertungen",
      spamProtectionInputLabel:
        "Füllen Sie dieses Feld nicht aus, wenn Sie kein Mensch sind",
      seeMore: "Sehen Sie mehr {{value}}",
      itineraries: "Reiseangebote",
      itinerariesSlug: "angebote",
      back: "Zurück",
      toTop: "Top",
      metaTitleFragment: " | Curacao, das karibische ziel",
      DAY: "TAG",
      DAYS: "TAGE",
      viewFullItinerary: "Vollständiges Angebot",
      visitedCuracaoBefore: "Ich habe Curaçao schon besucht",
      travelUpdates: "Reisehinweise",
      chooseDates: "Daten auswählen",
      Services: "Services",
      article: "Artikel", // search type
      activity: "Aktivität", // search type
      category: "Kategorie", // search type
      questions: "FAQ", // search type
      event: "Event", // search type
      itinerary: "Reiseroute", // search type
      page: "Seite", // search type
      visitWebsite: "Besuche Website",
      followUs: "Folgen Sie uns",
    },
  },
  pt: {
    translations: {
      viewFilters: "Ver Filtros",
      filter: "Filtros",
      viewAll: "Ver Todos ",
      youMayAlsoLikeTitle: "Outra Informações de Interesse",
      youMayAlsoLikeSubtitle:
        "Aqui há outras experiências em Curaçao que acreditamos que você irá gostar.",
      showMap: "Mostrar Mapa",
      hideMap: "Ocultar Mapa",
      reserveNow: "Reserve Agora",
      moreInformation: "Mais Informações",
      more: "Mais",
      readMore: "Leia mais",
      loadMore: "Carregar mais",
      learnMore: "Saiba mais",
      pastEvent: "Eventos Anteriores",
      noEventsFound: "Não Foram Encontrados Resultados",
      menu: "Menu",
      shareOn: "Compartilhe",
      facebook: "Facebook",
      whatsapp: "WhatsApp",
      twitter: "Twitter",
      email: "Email",
      copyLink: "Copie Link",
      messenger: "Messenger",
      sms: "SMS",
      yourFavourites: "Meus Favoritos",
      favouritesText1: "Você ainda não salvou nenhum local favorito.",
      favouritesText2:
        "Sempre que você quiser salvar algo para mais tarde, certifique-se de clicar no ",
      emptyImage: "Empty image",
      share: "Compartilhar",
      shareLink: "Compartilhar link",
      activities: "Atividades",
      accommodation: "Hospedagem",
      events: "Eventos",
      accommodations: "Acomodação",
      thingsToDo: "O Que Fazer",
      whereToStay: "Onde ficar",
      visitorInformationCategories: "Informações ao Visitante",
      tripAdvisorTravelerRating: "Pontuação dos viajantes TripAdvisor",
      reviews: "avaliações",
      iAccept: "Eu aceito",
      confirmSelection: "Confirmar seleção",
      giveConsent: "Autorizar",
      withdrawConsent: "Retirar autorização",
      searchSomething: "Procurar",
      searchResults: "resultados da pesquisa",
      tripAdvisorReviews: "{{value}} Avaliações no TripAdvisor",
      spamProtectionInputLabel: "Não preencha este campo se você é humano",
      seeMore: "Veja mais {{value}}",
      itineraries: "Itinerários",
      itinerariesSlug: "itinerarios",
      back: "Volte",
      toTop: "Top",
      metaTitleFragment: " | Curaçao, O refúgio caribenho perfeito",
      DAY: "DIA",
      DAYS: "DIAS",
      viewFullItinerary: "Ver Itinerário Completo",
      visitedCuracaoBefore: "Eu visitei Curaçao antes",
      travelUpdates: "Conselhos de Viagem",
      chooseDates: "Escolher datas",
      Services: "Serviços",
      article: "Artigo", // search type
      activity: "Atividade", // search type
      category: "Categoria", // search type
      questions: "Perguntas frequentes", // search type
      event: "Evento", // search type
      itinerary: "Itinerário", // search type
      page: "Página", // search type
      visitWebsite: "Visite o site",
      followUs: "Siga-nos",
    },
  },
} as const;

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: getBrowserLanguage(),
    fallbackLng: "en",
    debug: process.env.NODE_ENV === "development",
    ns: ["translations"],
    defaultNS: "translations",
    fallbackNS: "translations",
    keySeparator: false, // we use content as keys
    interpolation: {
      escapeValue: false, // not needed for React
    },
    react: {
      // wait: true,
    },
  });

// export default i18n;
