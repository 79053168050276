import { Asset } from "contentful";
import * as React from "react";
import "./pictureWrapper.style.css";

interface IProps {
  className?: string;
  asset: Asset;
  assetTablet?: Asset | undefined;
  assetMobile?: Asset | undefined;
  small: [number | false, number | false] | false;
  medium: [number | false, number | false] | false;
  large: [number | false, number | false] | false;
  options?: Array<[string, string]>;
  outerClass?: string;
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
  onClick?: () => void;
  caption?: string | undefined;
}

const canUseWebP = () => {
  const elem: HTMLCanvasElement = document.createElement("canvas");

  if (!!(elem.getContext && elem.getContext("2d"))) {
    // was able or not to get WebP representation
    return elem.toDataURL("image/webp").indexOf("data:image/webp") === 0;
  } else {
    // very old browser like IE 8, canvas not supported
    return false;
  }
};

const useWebP = canUseWebP();

const PictureWrapper = (props: IProps) => {
  const {
    className = "",
    options = [],
    small = [100, 100],
    medium = [300, 300],
    large = [600, 600],
    asset,
    assetTablet,
    assetMobile,
    outerClass = "",
    onMouseOver = () => false,
    onMouseLeave = () => false,
    onClick = () => false,
    caption,
  } = props;

  const decidedFileType = useWebP
    ? "webp"
    : asset.fields.file.contentType === "image/jpeg" ||
      asset.fields.file.contentType === "image/jpg"
    ? "jpg"
    : "png";

  if (!asset.fields) {
    return null;
  }

  // Set the default quality
  if (!options.find((o) => o[0] === "q")) {
    options.push(["q", "75"]);
  }
  const validImage = medium || large || small;

  const optionsArray = options.map((item) => item.join("="));
  return (
    <picture className={outerClass} style={{ zIndex: 0 }}>
      {small && (
        <source
          srcSet={`${
            assetMobile ? assetMobile.fields.file.url : asset.fields.file.url
          }?${small[0] ? `w=${small[0]}&` : ""}${
            small[1] ? `h=${small[1]}` : ""
          }${getFileType(decidedFileType)}${
            options.length > 0 ? "&" + optionsArray.join("&") : ""
          }`}
          media="(max-width: 767px)"
        />
      )}
      {medium && (
        <source
          srcSet={`${
            assetTablet ? assetTablet.fields.file.url : asset.fields.file.url
          }?${medium[0] ? `w=${medium[0]}&` : ""}${
            medium[1] ? `h=${medium[1]}` : ""
          }${getFileType(decidedFileType)}${
            options.length > 0 ? "&" + optionsArray.join("&") : ""
          }`}
          media="(max-width: 992px) AND (min-width: 768px)"
        />
      )}
      {large && (
        <source
          srcSet={`${asset.fields.file.url}?${
            large[0] ? `w=${large[0]}&` : ""
          }${large[1] ? `h=${large[1]}` : ""}${getFileType(decidedFileType)}${
            options.length > 0 ? "&" + optionsArray.join("&") : ""
          }`}
          media="(min-width: 992px)"
        />
      )}
      <img
        alt={asset.fields.title}
        src={`${asset.fields.file.url}?${
          validImage && validImage[0] ? `w=${validImage[0]}&` : ""
        }${
          validImage && validImage[1] ? `h=${validImage[1]}` : ""
        }${getFileType(decidedFileType)}${
          options.length > 0 ? "&" + optionsArray.join("&") : ""
        }`}
        className={`pictureWrapper ${className}`}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
      />
      {caption && <figcaption>{caption}</figcaption>}
    </picture>
  );
};

const getFileType = (type: string) =>
  `&fm=${type}${type === "jpg" ? "&fl=progressive" : ""}`;

export default PictureWrapper;
