import * as React from "react";
import "./label.style.css";

interface IProps {
  className?: string;
  children?: any;
  text?: string;
  icon?: string | boolean;
  errorMessage?: string | boolean;
}

const Label = (props: IProps) => {
  const {
    className = "",
    children,
    text = "",
    icon = false,
    errorMessage = "",
  } = props;
  return (
    <label className={`label ${className}`}>
      <div
        className={`body--1 label__text ${
          icon === "valid" ? "-valid" : icon === "error" ? "-error" : ""
        }`}
      >
        {text}
      </div>
      <div className="label__content">{children}</div>
      <div className="body--2 label__error">{errorMessage}</div>
    </label>
  );
};

export default Label;
