import { Entry, Locale, LocaleCollection } from "contentful";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import LazyLoad from "react-lazyload";
// import Logo from "../../Assets/Logo/SVG/logo.svg";
import FacebookCircle from "../../Assets/Sharing-Social Media/SVG/facebook-single-turq.svg";
import InstagramCircle from "../../Assets/Sharing-Social Media/SVG/instagram-turq.svg";
import TwitterCircle from "../../Assets/Sharing-Social Media/SVG/x-turq.svg";
import YouTubeCircle from "../../Assets/Sharing-Social Media/SVG/youtube-turq.svg";
import TikTokCircle from "../../Assets/Sharing-Social Media/SVG/tiktok-single-turq.svg";
import PinterestCircle from "../../Assets/Sharing-Social Media/SVG/pinterest-single-turq.svg";
// import Title from "../../components/Title";
import { IFooter } from "../../contentfulTypes";
import { AppContext } from "../../context";
import { client, getCachedQuery } from "../../util/contentfulQueries";
import customGA from "../../util/customGA";
import Container from "../Container";
// import Form, { Checkbox, isEmail, TextInput } from "../Form";
// import LanguageSwitcher from "../LanguageSwitcher";
// import { ResponsiveList, TickListItem } from "../List";
import NavigationLink from "../NavigationLink";
import "./footer.style.css";

interface INavigationState {
  locales: LocaleCollection | null;
  data: Entry<IFooter> | null;
  gdpr: boolean;
  email: string;
  emailInvalid: boolean;
  gdprInvalid: boolean;
  isSending: boolean;
  sendSuccessful: boolean;
  sendError: boolean;
  sendMessage: boolean;
  visitedCuracao: boolean;
  subscribeOpen: boolean;
  subscribeWait: boolean;
  subscribeLoaded: boolean;
}

interface IProps extends WithTranslation {
  history: any;
}

class Footer extends React.Component<IProps, INavigationState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      locales: null,
      data: null,
      gdpr: false,
      email: "",
      emailInvalid: false,
      gdprInvalid: false,
      isSending: false,
      sendSuccessful: false,
      sendError: false,
      sendMessage: false,
      visitedCuracao: false,
      subscribeOpen: false,
      subscribeWait: false,
      subscribeLoaded: false,
    };
    this.setLocales();
    this.getData(props.i18n.language);
  }

  // Needed, because after language switch this component stays mounted and
  // data needs to be refetched in the correct language.
  componentDidUpdate() {
    if (
      !this.state.data ||
      this.state.data.sys.locale !== this.props.i18n.language
    ) {
      this.getData(this.props.i18n.language);
    }
  }

  getData = async (language: string) => {
    const footerData = await getCachedQuery<IFooter>(
      {
        content_type: "footer",
        limit: 1,
        include: 2,
      },
      language
    );

    const data =
      footerData &&
      footerData.items &&
      footerData.items.length > 0 &&
      footerData.items[0];
    if (data) {
      this.setState({ data });
    }
  };

  async setLocales() {
    if (!this.state.locales) {
      const locales = await client.getLocales();
      this.setState({ locales });
    }
  }

  translateLocaleName(name: string) {
    const lowerCaseName = name.toLowerCase();
    if (lowerCaseName.startsWith("english") || lowerCaseName === "en") {
      return "English";
    }
    if (lowerCaseName.startsWith("spanish") || lowerCaseName === "es") {
      return "Español";
    }
    if (lowerCaseName.startsWith("dutch") || lowerCaseName === "nl") {
      return "Nederlands";
    }
    if (lowerCaseName.startsWith("french") || lowerCaseName === "fr") {
      return "Français";
    }
    if (lowerCaseName.startsWith("german") || lowerCaseName === "de") {
      return "Deutsch";
    }
    if (lowerCaseName.startsWith("portuguese") || lowerCaseName === "pt") {
      return "Português";
    }
    return name;
  }

  async handleLanguageSwitch(locale: Locale) {
    await this.props.i18n.changeLanguage(locale.code);
    this.props.history.push(`/${locale.code}/`);
  }

  async getContactaScript() {
    if (!this.state.subscribeLoaded && this.state.data !== null) {
      this.setState({ subscribeWait: true });
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = `https://forms.contacta.io/${this.state.data.fields.contactaId}.js`;
      document.getElementsByTagName("head")[0].appendChild(script);
      script.onload = () => {
        this.setState({ subscribeWait: false, subscribeLoaded: true });
      };
      customGA("event", "generate_lead");
    }
  }
  /*
		<div className="footer__logo">
			<img src={Logo} role="presentation" alt="" />
		</div>
		<LanguageSwitcher history={this.props.history} />
	*/

  render() {
    if (!this.state.data) {
      return null;
    }
    // const locale = this.props.i18n.language;
    const footerData = this.state.data;
    return (
      <LazyLoad height={400} offset={200}>
        <footer className="footer -option-3">
          <Container>
            <div className="footer__email-container">
              <h2 className="footer__email-text">{footerData.fields.title}</h2>
              <div className="footer__email-form-container">
                <input
                  className="button--email-form footer__email-form-btn"
                  type="button"
                  value={footerData.fields.signUpButtonText}
                  onClick={() => {
                    this.setState({ subscribeOpen: true });
                    this.getContactaScript();
                  }}
                />
                <div
                  className={`growForm ${
                    this.state.subscribeOpen ? "active" : ""
                  }`}
                  id="emailSubscribe"
                >
                  <div className="esWrapper">
                    <div
                      className="close"
                      role="button"
                      onClick={() => {
                        this.setState({ subscribeOpen: false });
                      }}
                    >
                      &#10005;
                    </div>
                    <div
                      className={`loading ${
                        this.state.subscribeWait ? "active" : ""
                      }`}
                    />
                    <div id={`CONTACTA_${footerData.fields.contactaId}`} />
                  </div>
                </div>
              </div>
            </div>

            <div className="footer__divider" />

            <div className="footer__columns">
              <div className="footer__column-wrapper">
                <h4 className="footer__column-heading">
                  {footerData.fields.mainLinksTitle}
                </h4>
                <ul className="body--2 footer__column">
                  {footerData.fields.mainLinks.map((mainLink, i) => (
                    <li key={i}>
                      <NavigationLink link={mainLink} />
                    </li>
                  ))}
                </ul>
              </div>
              <div className="footer__column-wrapper">
                <h4 className="footer__column-heading">
                  {footerData.fields.visitorLinksTitle}
                </h4>
                <ul className="body--2 footer__column">
                  {footerData.fields.visitorLinks.map((visitorLink, i) => (
                    <li key={i}>
                      <NavigationLink link={visitorLink} />
                    </li>
                  ))}
                </ul>
              </div>
              <div className="footer__column-wrapper">
                <h4 className="footer__column-heading">
                  {footerData.fields.aboutLinksTitle}
                </h4>
                <ul className="body--2 footer__column">
                  {footerData.fields.aboutLinks.map((aboutLink, i) => (
                    <li key={i}>
                      <NavigationLink link={aboutLink} />
                    </li>
                  ))}
                </ul>
                <h4 className="footer__column-heading footer__heading-inline">
                  {this.props.i18n.t("followUs")}
                </h4>
                <ul className="footer__social-media-icons">
                  {footerData.fields.facebook && (
                    <li>
                      <a
                        href={footerData.fields.facebook}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={FacebookCircle} alt="Curacao Facebook page" />
                      </a>
                    </li>
                  )}
                  {footerData.fields.instagram && (
                    <li>
                      <a
                        href={footerData.fields.instagram}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={InstagramCircle}
                          alt="Curacao Instagram page"
                        />
                      </a>
                    </li>
                  )}
                  {footerData.fields.twitter && (
                    <li>
                      <a
                        href={footerData.fields.twitter}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={TwitterCircle} alt="Curacao Twitter page" />
                      </a>
                    </li>
                  )}
                  {footerData.fields.youTube && (
                    <li>
                      <a
                        href={footerData.fields.youTube}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={YouTubeCircle} alt="Curacao YouTube page" />
                      </a>
                    </li>
                  )}
                  {footerData.fields.pinterest && (
                    <li>
                      <a
                        href={footerData.fields.pinterest}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={PinterestCircle}
                          alt="Curacao Pinterest page"
                        />
                      </a>
                    </li>
                  )}
                  {footerData.fields.tikTok && (
                    <li>
                      <a
                        href={footerData.fields.tikTok}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={TikTokCircle} alt="Curacao TikTok page" />
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            </div>
            <div className="footer__information-pages">
              {footerData.fields.bottomLinks && (
                <ul>
                  {footerData.fields.bottomLinks.map((bottomLink, i) => (
                    <li key={i}>
                      <NavigationLink link={bottomLink} />
                    </li>
                  ))}
                </ul>
              )}
              <div className="footer__copyright">
                © {new Date().getFullYear()} Curaçao Tourist Board
              </div>
            </div>
          </Container>
        </footer>
      </LazyLoad>
    );
  }
}

Footer.contextType = AppContext;

export default withTranslation()(Footer);
