import React from "react";
// @ts-ignore
// import nl2br from "react-nl2br";
import richTextToHtml from "../../util/richTextToHtml";
import "./richText.style.css";

interface IProps {
  className?: string;
  data: any;
  history: any;
  type?: "default" | "article" | "question" | "basic" | "white";
}

const RichText = (props: IProps) => {
  const { className, data, history, type = "default" } = props;
  const richText = richTextToHtml(data, history);
  const text = richText.dangerouslySetInnerHTML.__html.replace(
    /(\r\n|\n|\r)/g,
    "<br/>"
  );
  return (
    <div
      className={`richText -${type} ${className}`}
      role="presentation"
      onClick={richText.onClick}
      dangerouslySetInnerHTML={{
        __html: text,
      }}
    />
  );
};

export default RichText;
