import React from "react";
import Container from "../../Container";
import "./bannerDialogue.style.css";

interface IProps {
  className?: string;
  outerClass?: string;
  open?: boolean;
  children?: any;
}

const BannerDialogue = (props: IProps) => {
  const { className, outerClass = "", open = false, children } = props;
  return (
    <div
      className={`bannerDialogue ${open ? "-open" : "-closed"} ${outerClass}`}
    >
      <Container className={`bannerDialogue__container ${className}`}>
        {children}
      </Container>
    </div>
  );
};

export default BannerDialogue;
