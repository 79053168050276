import * as React from "react";
import ToggleButton from "../../ToggleButton";
import "./toggleListItem.style.css";

interface IProps {
  className?: string;
  onClick?: (id: string, isOn: boolean) => void;
  text: string;
  id: string;
  ticked?: boolean;
}

const ToggleListItem = ({
  className = "",
  onClick,
  text,
  ticked = false,
  id,
}: IProps) => (
  <li
    className={`toggleListItem
        ${className}`}
  >
    <label className={`toggleListItem__label ${ticked ? "-checked" : ""}`}>
      {text}
      <ToggleButton
        className="toggleListItem__toggle"
        on={ticked}
        onClick={(isOn: boolean) => {
          if (onClick) {
            onClick(id, isOn);
          }
        }}
      />
    </label>
  </li>
);

export default ToggleListItem;
