import * as React from "react";
import Label from "../Label/Label";
import "./textInput.style.css";

interface IProps {
  className?: string;
  value?: string;
  placeholder?: string;
  title?: string;
  icon?: boolean | string;
  errorMessage?: string | boolean;
  onChange?: any;
  style?: "default" | "transparent";
  name: string;
}

const TextInput = (props: IProps) => {
  const {
    className = "",
    value = "",
    placeholder = "",
    title = "",
    errorMessage = false,
    onChange,
    name,
    icon = false,
    style = "default",
  } = props;
  return (
    <Label text={title} icon={icon} errorMessage={errorMessage}>
      <input
        className={`textInput -${style} ${className}`}
        type="text"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        name={name}
      />
    </Label>
  );
};

export default TextInput;
