import * as React from "react";
import { useTranslation } from "react-i18next";
import EmptyImageIcon from "../../Assets/Save Favorites/SVG/empty-image.svg";
import "./emptyCard.style.css";

interface IProps {
  className?: string;
  image?: boolean;
}

const EmptyCard = (props: IProps) => {
  const { image = false } = props;
  const { i18n } = useTranslation();
  return image ? (
    <div className={`emptyCard ${image ? "-empty-img" : ""}`}>
      <img
        alt={i18n.t("emptyImage")}
        src={EmptyImageIcon}
        className="emptyCard__empty-img"
      />
      <div className="body--1 emptyCard__text">{i18n.t("favouritesText1")}</div>
    </div>
  ) : (
    <div className="emptyCard">
      <div className="emptyCard__container -img">
        <div className="emptyCard__image" />
      </div>
      <div className="emptyCard__container -txt1">
        <div className="emptyCard__text1" />
      </div>
      <div className="emptyCard__container -txt2">
        <div className="emptyCard__text2" />
      </div>
    </div>
  );
};

export default EmptyCard;
