import React from "react";
import { IAppInterface } from "./app.interface";

export const defaultAppData: IAppInterface = {
  navigationType: 1,
  setNavigationType: (type) => null,
  setTreeNavigation: (type) => null,
  siteConfig: false,
  isFavouritesOpen: false,
  setFavourites: (type) => null,
  addFavourite: (type) => null,
  removeFavourite: (type) => null,
  favouritesList: [],
  setHasAskedForGdprConsent: (type) => null,
  hasAskedForGdprConsent: false,
  setHasGdprConsent: (type) => null,
  hasGdprConsent: window.localStorage.getItem("gdprConsent") === "1",
  referrerUrl: "",
  treeNavigation: [],
  typeNavigation: [],
  subSelected: "",
  mainSelected: "",
};

export const AppContext = React.createContext(defaultAppData);
