import * as React from "react";
import "./locationMarker.style.css";

interface IProps {
  className?: string;
  text?: string | number;
  style?: "disabled" | "active" | "normal" | "close";
  sysId?: string;
  onClick?: any;
}

const LocationMarker = (props: IProps) => {
  const { className = "", text, style = "normal", sysId = "", onClick } = props;
  return (
    <svg
      className={`locationMarker -${style} ${className} ${
        sysId !== "" ? "cat" + sysId : ""
      }`}
      width="100%"
      height="100%"
      viewBox="0 0 92 113"
      onClick={onClick}
    >
      <g fill="none">
        <path
          className="locationMarker__marker"
          /* tslint:disable */
          d="M46.0092043,1 C21.1943138,1 1,22.0542059 1,47.9641221 C1,72.7320826 38.8482307,107.105905 43.1466558,110.944411 C43.9842504,111.635342 44.9967273,112 46.0092043,112 C47.012477,112 48.024954,111.635342 48.8533442,110.944411 C53.1609736,107.105905 91,72.7320826 91,47.9641221 C91,22.0542059 70.8148906,1 46.0092043,1"
          /* tslint:enable */
        />
        <circle cx="46" cy="46" r="30" fill="#FFF" />
      </g>
      <g
        id="noun_X_1741925_000000"
        transform="translate(26, 26) scale(4)"
        fill="#3399FF"
        className={"locationMarker__close"}
      >
        <polygon
          id="Path"
          /* tslint:disable */
          points="10 0.944206009 9.05579399 0 5.02145923 4.03433476 0.944206009 0 0 0.944206009 4.03433476 5.02145923 0 9.05579399 0.944206009 10 5.02145923 5.96566524 9.05579399 10 10 9.05579399 5.96566524 5.02145923"
          /* tslint:enable */
        />
      </g>
      <text
        x="46"
        y="56"
        textAnchor="middle"
        className="locationMarker__text"
        color="red"
      >
        {text}
      </text>
    </svg>
  );
};

export default LocationMarker;
