import * as React from "react";
import "./container.style.css";

interface IContainerProps {
  children: any;
  className?: string;
  thin?: boolean;
}

const Container = (props: IContainerProps) => {
  return (
    <div
      className={`container ${props.thin ? "-thin" : ""} ${
        props.className || ""
      }`}
    >
      {props.children}
    </div>
  );
};

export default Container;
