import * as React from "react";

interface IProps {
  button: (handleToggle: any, isToggleOn: boolean) => void;
  className?: string;
  content: (handleToggle: any, isToggleOn: boolean) => void;
}

interface IState {
  isToggleOn: boolean;
}

class Title extends React.Component<IProps, IState> {
  public state = {
    isToggleOn: false,
  };

  public dropdownBoxRef = React.createRef<HTMLDivElement>();

  constructor(props: any) {
    super(props);

    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
  }

  public componentWillMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  public componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  public handleClickOutside(e: any) {
    if (
      this.dropdownBoxRef.current &&
      !this.dropdownBoxRef.current.contains(e.target)
    ) {
      this.setState({
        isToggleOn: false,
      });
    }
    return;
  }

  public handleToggle(e: any) {
    e.stopPropagation();
    e.preventDefault();
    this.setState({
      isToggleOn: !this.state.isToggleOn,
    });
  }

  public render() {
    const { button, className = "", content } = this.props;

    return (
      // @ts-ignore
      <div className={`toggle ${className}`} ref={this.dropdownBoxRef}>
        {button(this.handleToggle, this.state.isToggleOn)}
        {content(this.handleToggle, this.state.isToggleOn)}
      </div>
    );
  }
}

export default Title;
