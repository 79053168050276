import * as React from "react";
import "./divider.style.css";

/*
const colors = new Map([
  ["blue", "-blue"],
  ["grey", "-grey"],
  ["pink", "-pink"],
  ["yellow", "-yellow"],
  ["orange", "-orange"],
  ["purple", "-purple"],
  ["white", "-white"],
]);
*/

interface IProps {
  className?: string;
  dark?: boolean;
}

const Divider = (props: IProps) => {
  const { className, dark = false } = props;
  return (
    <hr
      className={`divider ${dark ? "-dark" : ""} ${className ? className : ""}`}
    />
  );
};

export default Divider;
