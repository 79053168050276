import React from "react";
// import { useTranslation } from "react-i18next";
import DisableScroll from "../../../containers/DisableScroll";
import { RoundIconButton } from "../../Buttons";
import Divider from "../../Divider";
import "./slideUpDialogue.style.css";

interface IProps {
  open?: boolean;
  title: string;
  onClose?: any;
  children?: any;
}

const SlideUpDialogue = (props: IProps) => {
  const { open = false, onClose, children = "", title = "" } = props;
  const scrollRef = React.useRef(null);
  return (
    <DisableScroll disable={open} allow={scrollRef.current}>
      <div
        role="button"
        onClick={onClose}
        className={`slideUpDialogue ${open ? "-open" : "-closed"}`}
      />
      <div
        role="button"
        className={`slideUpDialogue__box ${open ? "-open" : "-closed"}`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <RoundIconButton
          icon="cross"
          onClick={onClose}
          className="slideUpDialogue__close-btn"
        />
        <h6 className="slideUpDialogue__title">{title}</h6>
        <Divider className="slideUpDialogue__divider" />
        <div className="slideUpDialogue__content" ref={scrollRef}>
          {children}
        </div>
      </div>
    </DisableScroll>
  );
};

export default SlideUpDialogue;
