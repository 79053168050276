import * as React from "react";
import "./submitButton.style.css";

interface IProps {
  className?: string;
  text: string;
  disabled: boolean;
}

const SubmitButton = (props: IProps) => {
  const { disabled, text } = props;
  return (
    <input
      className={`button submitButton ${disabled ? "-disabled" : "-active"}`}
      type="submit"
      disabled={disabled}
      value={text}
    />
  );
};

export default SubmitButton;
