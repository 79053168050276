import { Entry } from "contentful";
import * as React from "react";
import NavigationLink from "../../components/NavigationLink";
import NavigationSubMenu, { SubMenuType } from "./NavigationSubMenu";

interface IProps {
  items: Array<Entry<any>>;
  onClick?: () => void;
}

const NavigationRight = (props: IProps) => {
  const { items, onClick } = props;
  const mobileOnlyArray = items.map((itm) => !!itm.fields.mobileOnly);
  const firstNavItem = mobileOnlyArray.lastIndexOf(false);

  return (
    <ul className="nav__right" id="nav__right">
      {items.map((link, i) => (
        <li
          key={i}
          className={`nav__item 
        ${link.fields.mobileOnly ? "-mobile-only" : ""}
          ${i === firstNavItem ? "-first" : ""}
        `}
        >
          <NavigationLink link={link as any} onClick={onClick} />
          {link.fields.subNavigation && (
            <NavigationSubMenu
              type={link.fields.subNavigation as SubMenuType}
              active={false}
              baseLink={link.fields.link.fields.slug}
            />
          )}
        </li>
      ))}
    </ul>
  );
};

export default NavigationRight;
