import { createClient, EntryCollection } from "contentful";

export const client = createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE || "",
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN || "",
  environment: process.env.REACT_APP_CONTENTFUL_ENVIRONMENT || "master",
  host: process.env.REACT_APP_CONTENTFUL_HOST || "cdn.contentful.com",
});

// @ts-ignore
export const getCachedQuery = async <T>(query: object, locale: string) => {
  const queryWithLocale = {
    ...query,
    locale,
  };

  const queryWithLocaleString = JSON.stringify(queryWithLocale);
  const cachedResult = window.sessionStorage.getItem(queryWithLocaleString);

  let _lsTotal = 0;
  let _xLen;
  let _x;
  for (_x in window.sessionStorage) {
    if (!window.sessionStorage.hasOwnProperty(_x)) {
      continue;
    }
    _xLen = (window.sessionStorage[_x].length + _x.length) * 2;
    _lsTotal += _xLen;
    // console.log(_x.substr(0, 70) + " = " + (_xLen / 1024).toFixed(2) + " KB")
  }
  // console.log("Total = " + (_lsTotal / 1024).toFixed(2) + " KB");
  if (_lsTotal / 1024 > 4500) {
    window.sessionStorage.clear();
  }

  // if (cachedResult) temporarily replaced to make cache expire quicker for questions
  if (cachedResult) {
    return JSON.parse(cachedResult) as EntryCollection<T>;
  }

  try {
    let result = await client.getEntries(queryWithLocale);

    // When no results are found, fall back to default language (English).
    // Contentful should do this automatically, but sometimes we've seen this doesn't work.
    if (result.items.length === 0) {
      result = await client.getEntries({ ...query, locale: "en" });
    }

    window.sessionStorage.setItem(
      queryWithLocaleString,
      JSON.stringify(result)
    );
    return result as EntryCollection<T>;
  } catch (error) {
    return null;
  }
};

// @ts-ignore
export const getCachedQueryNoLn = async <T>(
  query: object,
  locale: string,
  justOne = true
) => {
  const queryFindID = {
    ...query,
    locale,
  };
  const queryAllLocales: any = {
    ...query,
    locale: "*",
  };

  const queryAllLocalesString = JSON.stringify(queryAllLocales);
  const cachedResult = window.sessionStorage.getItem(queryAllLocalesString);

  if (cachedResult) {
    return JSON.parse(cachedResult) as EntryCollection<T>;
  }

  try {
    // can't get all languages output when getting by specific locale
    // but can't get item from non-fallback lang slug matching when getting by all locales,
    //    so need to get sys.id of needed item first in English
    let IDResult = await client.getEntries(queryFindID);

    // When no results are found, fall back to default language (English).
    // Contentful should do this automatically, but sometimes we've seen this doesn't work.
    if (IDResult.items.length === 0) {
      IDResult = await client.getEntries({ ...query, locale: "en" });
    }

    const allLnQuery = justOne
      ? {
          // @ts-ignore
          content_type: queryAllLocales.content_type,
          "sys.id[match]": IDResult.items[0].sys.id,
          limit: 1,
          locale: "*",
        }
      : {
          // @ts-ignore
          content_type: queryAllLocales.content_type,
          include: queryAllLocales.include ? queryAllLocales.include : 2,
          select: queryAllLocales.select ? queryAllLocales.select : "",
          limit: 300,
          locale: "*",
        };

    const result = await client.getEntries(allLnQuery);

    // still cache as queryAllLocales 'cause no one cares about sys.id
    window.sessionStorage.setItem(
      queryAllLocalesString,
      JSON.stringify(result)
    );
    return result as EntryCollection<T>;
  } catch (error) {
    return null;
  }
};
