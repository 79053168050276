import * as React from "react";
import "./locationMarker.style.css";

interface IProps {
  className?: string;
  text?: string | number;
  style?: "disabled" | "active" | "normal" | "close";
  sysId?: string;
  onClick?: any;
}

const LocationMarkerIcon = (props: IProps) => {
  const { className = "", style = "normal", sysId = "", onClick } = props;
  return (
    <svg
      className={`locationMarker locationMarker__toilet -${style} ${className} ${
        sysId !== "" ? "cat" + sysId : ""
      }`}
      width="100%"
      height="100%"
      viewBox="0 0 465.978 465.978"
      onClick={onClick}
    >
      <g transform="matrix(1, 0, 0, 1, -8.372327, -2.576101)">
        <ellipse
          fill="#636466"
          cx="261.796"
          cy="268.237"
          rx="225.086"
          ry="225.086"
        />
      </g>
      <g transform="matrix(0.538407, 0, 0, 0.538407, 118.91613, 127.704277)">
        <g>
          <path
            d="M146.645,109.31c-54.918,0-55.673,0-101.357,0c-24.466,0-44.468,19.904-44.591,44.367L0,292.109 c-0.052,10.407,8.342,18.885,18.747,18.938c0.032,0,0.065,0,0.098,0c10.363,0,18.787-8.375,18.841-18.747l0.696-138.432 c0-0.016,0-0.031,0-0.047c0.024-2.055,1.704-3.706,3.759-3.694c2.055,0.012,3.715,1.683,3.715,3.738l0.008,316.757 c0,12.488,10.124,22.612,22.612,22.612s22.612-10.124,22.612-22.612V289.888h9.763v180.734c0,12.488,10.123,22.611,22.611,22.611 s22.611-10.123,22.611-22.611c0-298.894-0.4-127.966-0.41-316.481c0-2.121,1.674-3.863,3.794-3.946 c2.12-0.083,3.928,1.521,4.092,3.635c0,0.001,0,0.002,0,0.002l-0.128,138.354c-0.01,10.406,8.419,18.851,18.825,18.861 c0.007,0,0.011,0,0.018,0c10.397,0,18.833-8.426,18.842-18.825l0.128-138.431c0.002-0.039,0.002-0.075,0.001-0.114 C191.112,129.214,171.109,109.31,146.645,109.31z"
            fill="#FFFFFF"
          />
        </g>
      </g>
      <g transform="matrix(0.538407, 0, 0, 0.538407, 118.91613, 127.704277)">
        <g>
          <circle cx="95.966" cy="57.813" r="39.047" fill="#FFFFFF" />
        </g>
      </g>
      <g transform="matrix(0.538407, 0, 0, 0.538407, 118.91613, 127.704277)">
        <g>
          <path
            d="M510.941,262.849c-0.346-0.986-34.611-98.717-40.002-114.406c-8.679-25.253-23.23-38.057-43.249-38.057 c-28.025,0-37.18,0-65.153,0c-20.02,0-34.571,12.805-43.249,38.057c-5.391,15.688-39.656,113.421-40.002,114.406 c-3.418,9.748,1.714,20.423,11.464,23.841c9.758,3.42,20.425-1.725,23.84-11.462c1.106-3.154,21.721-60.433,33.222-93.389 c-1.204,9.838,2.264-10.578-26.737,139.154c-1.246,6.431,3.711,12.379,10.201,12.379c4.029,0,8.862,0,14.273,0v135.678 c0,12.396,10.049,22.446,22.446,22.446c12.397,0,22.446-10.049,22.446-22.446v-135.68c3.227,0,6.465,0,9.692,0v135.68 c0,12.396,10.049,22.446,22.446,22.446c12.397,0,22.446-10.049,22.446-22.446v-135.68c5.411,0,10.245,0,14.273,0 c6.522,0,11.446-5.952,10.201-12.379c-28.737-148.369-25.57-131.138-26.55-139.154c11.593,33.211,31.602,90.284,32.691,93.389 c3.421,9.756,14.101,14.878,23.84,11.462C509.227,283.27,514.359,272.597,510.941,262.849z"
            fill="#FFFFFF"
          />
        </g>
      </g>
      <g transform="matrix(0.538407, 0, 0, 0.538407, 118.91613, 127.704277)">
        <g>
          <circle cx="395.283" cy="59.265" r="38.767" fill="#FFFFFF" />
        </g>
      </g>
      <g transform="matrix(0.538407, 0, 0, 0.538407, 118.91613, 127.704277)">
        <g>
          <path
            d="M239.761,43.925c-7.969,0-14.43,6.46-14.43,14.43v395.282c0,7.969,6.46,14.429,14.43,14.429 c7.97,0,14.43-6.459,14.43-14.429V58.355C254.191,50.385,247.73,43.925,239.761,43.925z"
            fill="#FFFFFF"
          />
        </g>
      </g>
    </svg>
  );
};

export default LocationMarkerIcon;
