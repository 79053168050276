import * as React from "react";
import { useTranslation } from "react-i18next";
import ShareIcon from "../../../Assets/Sharing-Social Media/SVG/share-icon.svg";
import ShareDotsIcon from "../../../Assets/Sharing-Social Media/SVG/share-dots.svg";
import "./iconButton.style.css";

interface IProps {
  className?: string;
  icon: "share" | "share-new" | "share-new-text";
  onClick: () => void;
}

const getIcon = (icon: string) => {
  if (icon === "share-new" || icon === "share-new-text") {
    return ShareDotsIcon;
  }
  return ShareIcon;
};

const IconButton = (props: IProps) => {
  const { t } = useTranslation();
  const { className, icon, onClick } = props;
  return (
    <span className="iconButton-container" onClick={onClick}>
      <img
        src={getIcon(icon)}
        role="presentation"
        alt=""
        className={`iconButton ${icon} ${className || ""}`}
      />
      {icon === "share-new-text" && (
        <span className="iconButton-text">{t("share").toUpperCase()}</span>
      )}
    </span>
  );
};

export default IconButton;
