// import { Entry } from "contentful";
import React from "react";
import { useTranslation } from "react-i18next";
import sunIcon from "../../Assets/Featured Content/SVG/sun.svg";
import { MultiButton } from "../../components/Buttons";
import { BannerDialogue } from "../../components/Dialogue";
import { Checkbox } from "../../components/Form";
import RichText from "../../components/RichText";
// import { ISiteConfig } from "../../contentfulTypes";
import { AppContext, IAppInterface } from "../../context";
import "./gdprConsent.style.css";

const GdprConsent = ({
  location,
  history,
}: {
  location: any;
  history: any;
}) => {
  const {
    hasAskedForGdprConsent,
    setHasAskedForGdprConsent,
    hasGdprConsent,
    setHasGdprConsent,
    siteConfig,
  }: IAppInterface = React.useContext(AppContext);

  const isPrivacyPage = location.pathname.endsWith(
    `/${siteConfig && siteConfig.fields.gdprWithdrawPage.fields.slug}`
  );
  const hasResponded = hasAskedForGdprConsent;
  const hasAccepted = hasGdprConsent;
  const showConfirm = (isPrivacyPage && !hasResponded) || !isPrivacyPage;
  const showGiveConsent = isPrivacyPage && !hasAccepted;
  const [checkedGdpr, setCheckedGdpr] = React.useState(true);
  const [hideBanner, setHideBanner] = React.useState(false);
  const [removeBanner, setremoveBanner] = React.useState(false);

  React.useEffect(() => {
    if (hasAskedForGdprConsent) {
      setremoveBanner(true);
      setHideBanner(true);
      setTimeout(() => {
        setremoveBanner(false);
      }, 1000);
      setTimeout(() => {
        setHideBanner(false);
      }, 1100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { i18n } = useTranslation();

  return (
    <BannerDialogue
      outerClass={`gdprConsent__outer ${removeBanner ? "-transparent" : ""}`}
      className={`gdprConsent `}
      open={
        ((!hasAskedForGdprConsent && !hasGdprConsent) || isPrivacyPage) &&
        !hideBanner
      }
    >
      <div className="gdprConsent__icon">
        <img src={sunIcon} alt="sun-icon" className="gdprConsent__icon-img" />
      </div>
      <div className="gdprConsent__text">
        {siteConfig && typeof siteConfig.fields.gdprConfirmMessage === "string"
          ? siteConfig.fields.gdprConfirmMessage
          : siteConfig && (
              <RichText
                data={siteConfig.fields.gdprConfirmMessage}
                history={history}
                type="white"
              />
            )}
      </div>
      <div className="gdprConsent__right">
        {showConfirm && (
          <Checkbox
            className="gdprConsent__checkbox"
            name="gdpr-check"
            checked={checkedGdpr}
            onChange={() => {
              setCheckedGdpr(!checkedGdpr);
            }}
            text={i18n.t("iAccept")}
            errorMessage={false}
            // eslint-disable-next-line
            style="white"
          />
        )}
        <MultiButton
          type="important-alt"
          text={
            showConfirm
              ? i18n.t("confirmSelection")
              : showGiveConsent
              ? i18n.t("giveConsent")
              : i18n.t("withdrawConsent")
          }
          onClick={() => {
            if (showConfirm) {
              window.localStorage.setItem(
                "gdprConsent",
                checkedGdpr ? "1" : "0"
              );
              setHideBanner(true);
              setHasGdprConsent(checkedGdpr);
              setTimeout(() => {
                setHasAskedForGdprConsent(true);
                setHideBanner(false);
              }, 1000);
            } else {
              if (showGiveConsent) {
                setHideBanner(true);
                setTimeout(() => {
                  setHasGdprConsent(true);
                  window.localStorage.setItem("gdprConsent", "1");
                  setHideBanner(false);
                }, 1000);
              } else {
                setHideBanner(true);
                setTimeout(() => {
                  setHasGdprConsent(false);
                  window.localStorage.setItem("gdprConsent", "0");
                  setHideBanner(false);
                }, 1000);
              }
            }
          }}
        />
      </div>
    </BannerDialogue>
  );
};

export default GdprConsent;
