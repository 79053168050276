import * as React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import NotFound from "./pages/NotFound/NotFound";
import Root from "./pages/Root";
import "./sass/main.css";

const App = () => (
  <Router>
    <Switch>
      <Route path="/" render={(routeProps) => <Root {...routeProps} />} />
      <Route component={NotFound} />
    </Switch>
  </Router>
);

export default App;
