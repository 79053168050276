import * as React from "react";
import "./roundIconButton.style.css";

interface IProps {
  onClick?: any;
  icon: string;
  className?: string;
  id?: string;
  light?: boolean;
}

const directions = new Map([
  ["left", "-left"],
  ["right", "-right"],
  ["up", "-up"],
  ["down", "-down"],
  ["cross", "-cross"],
]);

const RoundIconButton = ({
  icon,
  onClick,
  className = "",
  id = "",
  light = false,
}: IProps) => (
  <button
    id={id}
    className={`button--directional ${light ? "-light" : ""} ${
      directions.get(icon)
        ? directions.get(icon)
        : directions.values().next().value
    } ${className}`}
    onClick={onClick}
  />
);

export default RoundIconButton;
