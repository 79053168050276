import "babel-polyfill";
import "react-app-polyfill/ie11";
// tslint:disable-next-line:ordered-imports
import * as React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import "./i18n";
import * as serviceWorker from "./serviceWorker";

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

// Service worker disabled, else the Netlify redirects form the _redirects file won't work after initial fetch
serviceWorker.unregister();
