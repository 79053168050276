// @ts-ignore
import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock";
import * as React from "react";

interface IProps {
  children: any;
  disable: boolean;
  allow?: any;
  allowOther?: any;
}

class DisableScroll extends React.Component<IProps, {}> {
  public state = {
    disabled: false,
  };
  public componentWillReceiveProps(newProps: IProps) {
    if (newProps.disable && this.props.allow !== null) {
      this.setState({
        disabled: true,
      });
      disableBodyScroll(this.props.allow, this.props.allowOther);
    } else if (!newProps.disable && this.state.disabled) {
      this.setState({
        disabled: false,
      });
      clearAllBodyScrollLocks();
    }
  }

  public render() {
    return <React.Fragment>{this.props.children}</React.Fragment>;
  }
}

export default DisableScroll;
