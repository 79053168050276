import * as React from "react";
// @ts-ignore
import nl2br from "react-nl2br";
import { RoundIconButton } from "../../components/Buttons";
import { DropdownBox } from "../../components/Dropdown";
import Toggle from "../../containers/Toggle";
import "./title.style.css";

interface IProps {
  text?: string;
  description?: string;
  className?: string;
  buttonId?: string;
  color?: string;
  dropdown?: (handleToggle: any) => void;
  heading?: "h1" | "h2" | "h3";
  expandWithFilter?: boolean;
}

const colors = new Map([
  ["blue", "-blue"],
  ["grey", "-grey"],
  ["pink", "-pink"],
  ["yellow", "-yellow"],
  ["orange", "-orange"],
  ["purple", "-purple"],
  ["white", "-white"],
]);

const Title = ({
  text,
  className,
  buttonId,
  color,
  dropdown,
  expandWithFilter = false,
  heading = "h1",
  description,
}: IProps) => (
  <div
    className={`title ${expandWithFilter ? "-expand-with-filter" : ""} ${
      className ? className : ""
    }`}
  >
    {React.createElement(
      heading || "h1",
      {
        className: `title ${
          expandWithFilter ? "-expand-with-filter" : ""
        } -${heading} ${className ? className : ""} ${
          (color && colors.get(color)) || colors.values().next().value
        }`,
      },
      <React.Fragment>
        <span className="title__text">{text}</span>
      </React.Fragment>
    )}
    {dropdown && (
      <Toggle
        className="title__dropdown-container"
        button={(handleToggle, isToggleOn) => (
          <RoundIconButton
            id={buttonId}
            onClick={handleToggle}
            icon={isToggleOn ? "up" : "down"}
            className={`title__dropdown-button`}
          />
        )}
        content={(handleToggle, isToggleOn) => (
          <React.Fragment>
            <DropdownBox open={isToggleOn} className={`title__dropdown-box`}>
              {dropdown(handleToggle)}
            </DropdownBox>
            {/*
            <DropdownBox open={isToggleOn} className="title__measure">
              {dropdown && dropdown(() => 1)}
            </DropdownBox>
            */}
          </React.Fragment>
        )}
      />
    )}
    {description && (
      <p className="body--1 title__description">{nl2br(description)}</p>
    )}
  </div>
);

export default Title;
