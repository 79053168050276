import * as React from "react";

interface IProps {
  className?: string;
  style?: string;
  onClick?: (ev?: any) => void;
  count: number;
}

const TripAdvisorReviewWhite = (props: IProps) => {
  const { className = "", onClick, count } = props;
  // tslint:disable:max-line-length
  return (
    <svg
      className={`tripAdvisorReviewWhiteSVG ${className}`}
      onClick={onClick && onClick}
      viewBox="0 0 80.65 12.98"
      preserveAspectRatio="xMidYMid meet"
    >
      <defs>
        <mask
          id="mask"
          x="69.38"
          y="1.44"
          width="11.27"
          height="11.55"
          maskUnits="userSpaceOnUse"
        >
          <g transform="translate(0 0)">
            <g id="mask-2">
              <polygon
                id="path-1"
                points="69.38 1.44 80.65 1.44 80.65 12.98 69.38 12.98 69.38 1.44"
                fill="#fff"
                fillRule="evenodd"
              />
            </g>
          </g>
        </mask>
        <mask
          id="mask-2-2"
          x="0"
          y="0"
          width="22.56"
          height="12.98"
          maskUnits="userSpaceOnUse"
        >
          <g transform="translate(0 0)">
            <g id="mask-4">
              <polygon
                id="path-3"
                points="0 0 22.56 0 22.56 12.98 0 12.98 0 0"
                fill="#fff"
                fillRule="evenodd"
              />
            </g>
          </g>
        </mask>
      </defs>
      <path
        id="outline1"
        d="M25.54,7.19a4.5,4.5,0,1,1,9,0,4.5,4.5,0,1,1-9,0m-1.13,0a5.63,5.63,0,1,0,11.26,0,5.63,5.63,0,1,0-11.26,0"
        transform="translate(0 0)"
        fill="#fff"
        fillRule="evenodd"
      />
      <path
        id="outline2"
        d="M39.38,11.39a4.49,4.49,0,0,1-2.33-2.58h0a4.67,4.67,0,0,1,.13-3.52,4.46,4.46,0,0,1,8.3.33,4.64,4.64,0,0,1-2.66,5.92,4.33,4.33,0,0,1-3.44-.14m-.07-9.6a5.7,5.7,0,0,0-3.16,3,5.81,5.81,0,0,0-.52,2.41,6,6,0,0,0,.35,2,5.72,5.72,0,0,0,2.92,3.24,5.45,5.45,0,0,0,4.32.17,5.82,5.82,0,0,0,3.33-7.41,5.61,5.61,0,0,0-7.24-3.42"
        transform="translate(0 0)"
        fill="#fff"
        fillRule="evenodd"
      />
      <path
        id="outline3"
        d="M48,7.19a4.5,4.5,0,1,1,9,0,4.5,4.5,0,1,1-9,0m-1.14,0a5.63,5.63,0,1,0,11.26,0,5.63,5.63,0,1,0-11.26,0"
        transform="translate(0 0)"
        fill="#fff"
        fillRule="evenodd"
      />
      <path
        id="outline4"
        d="M61.87,11.39a4.52,4.52,0,0,1-2.33-2.58h0a4.67,4.67,0,0,1,.13-3.52,4.46,4.46,0,0,1,8.3.33,4.64,4.64,0,0,1-2.66,5.92,4.34,4.34,0,0,1-3.44-.14m-.06-9.6a5.69,5.69,0,0,0-3.17,3,5.81,5.81,0,0,0-.52,2.41,6,6,0,0,0,.35,2,5.7,5.7,0,0,0,2.93,3.24,5.43,5.43,0,0,0,4.31.17A5.82,5.82,0,0,0,69,5.21a5.61,5.61,0,0,0-7.23-3.42"
        transform="translate(0 0)"
        fill="#fff"
        fillRule="evenodd"
      />
      <g mask="url(#mask)">
        <path
          id="outline5"
          d="M73.13,11.39A4.49,4.49,0,0,1,70.8,8.81h0a4.67,4.67,0,0,1,.13-3.52,4.46,4.46,0,0,1,8.3.33,4.64,4.64,0,0,1-2.66,5.92,4.34,4.34,0,0,1-3.44-.14m-.07-9.6a5.7,5.7,0,0,0-3.16,3,5.81,5.81,0,0,0-.52,2.41,6,6,0,0,0,.35,2,5.7,5.7,0,0,0,2.93,3.24,5.43,5.43,0,0,0,4.31.17A5.82,5.82,0,0,0,80.3,5.21a5.61,5.61,0,0,0-7.24-3.42"
          transform="translate(0 0)"
          fill="#fff"
          fillRule="evenodd"
        />
      </g>
      <g id="owl">
        <path
          id="Fill-22"
          d="M5.58,6.51a.92.92,0,1,0,.94.92.93.93,0,0,0-.94-.92"
          transform="translate(0 0)"
          fill="#fff"
          fillRule="evenodd"
        />
        <path
          id="Fill-24"
          d="M16.9,6.51a.92.92,0,1,0,.93.92.93.93,0,0,0-.93-.92"
          transform="translate(0 0)"
          fill="#fff"
          fillRule="evenodd"
        />
        <g id="Group-28">
          <g mask="url(#mask-2-2)">
            <path
              id="Fill-26"
              d="M21.44,4.23A7.12,7.12,0,0,1,22.55,2H18.78a14,14,0,0,0-7.53-2A14.28,14.28,0,0,0,3.54,2.06H0A7.16,7.16,0,0,1,1.11,4.23,5.35,5.35,0,0,0,0,7.47,5.58,5.58,0,0,0,5.65,13a5.7,5.7,0,0,0,4.4-2.06l1.21,1.75,1.21-1.76a5.64,5.64,0,0,0,2.07,1.59,5.77,5.77,0,0,0,4.32.16,5.45,5.45,0,0,0,3.35-7,5.33,5.33,0,0,0-.77-1.36M15,11.47A4.41,4.41,0,0,1,12.68,9h0a4.24,4.24,0,0,1,.13-3.35,4.44,4.44,0,0,1,2.54-2.27A4.54,4.54,0,0,1,21.14,6a4.35,4.35,0,0,1-2.67,5.62A4.58,4.58,0,0,1,15,11.47m-9.37.38A4.45,4.45,0,0,1,1.14,7.47,4.45,4.45,0,0,1,5.65,3.09a4.45,4.45,0,0,1,4.51,4.38,4.45,4.45,0,0,1-4.51,4.38M15,2.35A5.5,5.5,0,0,0,11.78,5.2a5.2,5.2,0,0,0-.5,1.88A5.59,5.59,0,0,0,5.76,2a14.36,14.36,0,0,1,5.49-1,13.7,13.7,0,0,1,5.48,1A5.84,5.84,0,0,0,15,2.35"
              transform="translate(0 0)"
              fill="#fff"
              fillRule="evenodd"
            />
          </g>
        </g>
        <path
          id="Fill-29"
          d="M5.58,4.72A2.75,2.75,0,0,0,2.79,7.43a2.76,2.76,0,0,0,2.79,2.71A2.76,2.76,0,0,0,8.37,7.43,2.75,2.75,0,0,0,5.58,4.72m0,4.48A1.78,1.78,0,1,1,7.41,7.43,1.81,1.81,0,0,1,5.58,9.2"
          transform="translate(0 0)"
          fill="#fff"
          fillRule="evenodd"
        />
        <path
          id="Fill-31"
          d="M16.9,4.72a2.75,2.75,0,0,0-2.79,2.71,2.79,2.79,0,0,0,5.57,0A2.75,2.75,0,0,0,16.9,4.72m0,4.48a1.78,1.78,0,1,1,1.82-1.77A1.81,1.81,0,0,1,16.9,9.2"
          transform="translate(0 0)"
          fill="#fff"
          fillRule="evenodd"
        />
      </g>
      <path
        id="circle05"
        d="M30,4.3H30A2.85,2.85,0,0,0,30,10H30Z"
        transform="translate(0 0)"
        fill={count >= 0.5 ? "#fff" : "none"}
        fillRule="evenodd"
      />
      <path
        id="circle10"
        d="M30,10h.07a2.85,2.85,0,0,0,0-5.7H30Z"
        transform="translate(0 0)"
        fill={count >= 1.0 ? "#fff" : "none"}
        fillRule="evenodd"
      />
      <path
        id="circle15"
        d="M41.25,4.23h-.07A2.81,2.81,0,0,0,38.4,7.07a2.82,2.82,0,0,0,2.78,2.86h.07Z"
        transform="translate(0 0)"
        fill={count >= 1.5 ? "#fff" : "none"}
        fillRule="evenodd"
      />
      <path
        id="circle20"
        d="M41.25,9.92h.07a2.85,2.85,0,0,0,0-5.7h-.07Z"
        transform="translate(0 0)"
        fill={count >= 2.0 ? "#fff" : "none"}
        fillRule="evenodd"
      />
      <path
        id="circle25"
        d="M52.46,4.23h-.07a2.81,2.81,0,0,0-2.78,2.84,2.82,2.82,0,0,0,2.78,2.86h.07Z"
        transform="translate(0 0)"
        fill={count >= 2.5 ? "#fff" : "none"}
        fillRule="evenodd"
      />
      <path
        id="circle30"
        d="M52.46,9.92h.07a2.85,2.85,0,0,0,0-5.7h-.07Z"
        transform="translate(0 0)"
        fill={count >= 3.0 ? "#fff" : "none"}
        fillRule="evenodd"
      />
      <path
        id="circle35"
        d="M63.77,4.37H63.7a2.85,2.85,0,0,0,0,5.7h.07Z"
        transform="translate(0 0)"
        fill={count >= 3.5 ? "#fff" : "none"}
        fillRule="evenodd"
      />
      <path
        id="circle40"
        d="M63.77,10.05h.07a2.85,2.85,0,0,0,0-5.7h-.07Z"
        transform="translate(0 0)"
        fill={count >= 4.0 ? "#fff" : "none"}
        fillRule="evenodd"
      />
      <path
        id="circle45"
        d="M75,4.23h-.07a2.85,2.85,0,0,0,0,5.7H75Z"
        transform="translate(0 0)"
        fill={count >= 4.5 ? "#fff" : "none"}
        fillRule="evenodd"
      />
      <path
        id="circle50"
        d="M75,9.92h.07a2.85,2.85,0,0,0,0-5.7H75Z"
        transform="translate(0 0)"
        fill={count >= 5.0 ? "#fff" : "none"}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default TripAdvisorReviewWhite;
