import * as React from "react";
import "./socialMediaLink.style.css";

interface IProps {
  onClick?: any;
  className?: string;
  to: string;
  type?: string;
}

const types = new Map([
  ["facebook", "-facebook"],
  ["twitter", "-twitter"],
  ["instagram", "-instagram"],
]);

const SocialLink = ({ onClick, className, to, type }: IProps) => {
  const values = {
    onClick: () => {
      if (onClick) {
        onClick();
      }
    },
    href: to,
    target: "_blank",
    rel: "noopener noreferrer",
    className: ` link--social-media ${
      (type && types.get(type)) || types.values().next().value
    } ${className ? className : ""}`,
  };
  return <a {...values}> </a>;
};

export default SocialLink;
