import React from "react";
import gtmParts from "react-google-tag-manager";

interface IGTag {
  gtmId: string;
  dataLayerName?: string;
  additionalEvents?: object;
  previewVariables?: string;
  scriptId?: string;
  gdprConsent: boolean;
  pathName: string;
}

interface IState {
  gdprConsent: boolean;
  pathName: string;
  time: number;
}

class GoogleTagManager extends React.Component<IGTag, IState> {
  constructor(props: IGTag) {
    super(props);
    this.state = {
      gdprConsent: this.props.gdprConsent,
      pathName: this.props.pathName,
      time: 0,
    };
  }

  public fetchCountry(dataLayerName: string, full: boolean) {
    const d = new Date();
    const t = d.getTime();
    this.setState({ time: t });
    fetch("https://pro.ip-api.com/json/?key=uPfbySuvBYo2XJs")
      .then((res) => res.json())
      .then((result) => {
        if ((window as any)[dataLayerName]) {
          if (full) {
            (window as any)[dataLayerName].push({
              event: "countryFound",
              cCode: result.countryCode,
              gdprConsent: this.state.gdprConsent,
              pathName: this.state.pathName,
            });
          } else {
            (window as any)[dataLayerName].push({
              cCode: result.countryCode,
            });
          }
        }
      });
  }

  public componentDidUpdate(prevProps: any) {
    const dataLayerName = this.props.dataLayerName || "dataLayer";
    // const dataLayerName = "dataLayer";
    const d = new Date();
    const t = d.getTime();
    // re-check the visitor's country every 30 minutes
    if (t - this.state.time > 1000 * 60 * 30) {
      this.fetchCountry(dataLayerName, false);
    }
    if (
      this.props.pathName !== this.state.pathName ||
      this.props.gdprConsent !== this.state.gdprConsent
    ) {
      this.setState(
        { pathName: this.props.pathName, gdprConsent: this.props.gdprConsent },
        () => {
          if ((window as any)[dataLayerName]) {
            (window as any)[dataLayerName].push({
              event: "countryFound",
              pathName: this.state.pathName,
              gdprConsent: this.state.gdprConsent,
            });
          }
        }
      );
    }
  }

  public componentDidMount() {
    const dataLayerName = this.props.dataLayerName || "dataLayer";
    // const dataLayerName = "dataLayer";
    const scriptId = this.props.scriptId || "react-google-tag-manager-gtm";
    // const scriptId = "react-google-tag-manager-gtm";

    // if (!(window as any)[dataLayerName]) {
    const gtmScriptNode = document.getElementById(scriptId);
    if (gtmScriptNode !== null && gtmScriptNode.textContent !== null) {
      /* tslint:disable-next-line */ // eslint-disable-next-line
      eval(gtmScriptNode.textContent);
    }
    // }

    this.fetchCountry(dataLayerName, true);
  }

  public render() {
    const gtm = gtmParts({
      id: this.props.gtmId,
      dataLayerName: this.props.dataLayerName || "dataLayer",
      additionalEvents: this.props.additionalEvents || {},
      previewVariables: this.props.previewVariables || false,
    });

    return (
      <div>
        <div>{gtm.noScriptAsReact()}</div>
        <div id={this.props.scriptId || "react-google-tag-manager-gtm"}>
          {gtm.scriptAsReact()}
        </div>
      </div>
    );
  }
}

export default GoogleTagManager;
