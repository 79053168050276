import * as React from "react";
import "./dropdownBox.style.css";

interface IDropdownBoxProps {
  className?: string;
  children?: any;
  open?: boolean;
  ref?: any;
}

const DropdownBox = ({ className, open, children }: IDropdownBoxProps) => (
  <div
    role="button"
    className={`dropdownBox ${open ? "-open" : "-closed"} ${className || ""}`}
    onClick={(e) => {
      e.stopPropagation();
    }}
  >
    {children}
  </div>
);

export default DropdownBox;
