import * as React from "react";
import "./search.style.css";

interface IProps {
  className?: string;
  style?: string;
  onClick?: (ev?: any) => void;
}

const styles = new Map([
  ["grey", "-grey"],
  ["grey-dark", "-grey-dark"],
  ["disabled", "-disabled"],
  ["active", "-active"],
  ["blue", "-blue"],
  ["blue-active", "-blue-active"],
]);

const SearchSVG = (props: IProps) => {
  const { className = "", style, onClick } = props;
  return (
    <svg
      className={`searchSVG ${
        style && styles.get(style)
          ? styles.get(style)
          : styles.values().next().value
      } ${className}`}
      onClick={onClick && onClick}
      viewBox="0 0 12 12"
      preserveAspectRatio="xMidYMid meet"
    >
      <g id="search-icon" stroke="none" fill="none">
        <g id="search-icon-t" transform="translate(-185.000000, -176.000000)">
          <g id="noun-search" transform="translate(185.000000, 176.000000)">
            <path
              className="searchSVG__border"
              d="M7.42344581,1.27380637 C5.72609016,-0.424912776 2.97130431,-0.424291445 1.27394867,1.27380637 C-0.424649555,2.97190419 -0.424649555,5.7256435 1.27394867,7.42436265 C2.82219547,8.97334102 5.24707801,9.10444187 6.95002524,7.82822785 L10.939308,11.8177947 C11.1809886,12.0607351 11.5755058,12.0607351 11.8178076,11.8177947 C12.0607308,11.5748542 12.0607308,11.1815516 11.8178076,10.9392325 L7.82852483,6.94966572 C9.10402672,5.24659725 8.97293519,2.82278474 7.42344581,1.27380637 Z M6.54494622,6.54580052 C5.33405817,7.75677478 3.36333631,7.75677478 2.15244826,6.54580052 C0.941560207,5.33482626 0.941560207,3.36334277 2.15244826,2.15236851 C3.36333631,0.94077292 5.33405817,0.941394251 6.54494622,2.15236851 C7.75583427,3.36334277 7.75707685,5.33420493 6.54494622,6.54580052 Z"
              id="search-shape"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SearchSVG;
