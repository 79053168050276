import * as React from "react";
import "./socialMediaButton.style.css";

interface IProps {
  onClick?: any;
  className?: string;
  to: string;
  type?: string;
}

const types = new Map([
  ["facebook", "-facebook"],
  ["twitter", "-twitter"],
  ["instagram", "-instagram"],
]);

const MultiButton = ({ onClick, className, to, type }: IProps) => {
  const values = {
    onClick: () => {
      if (onClick) {
        onClick();
      }
      window.open(to, "_blank", "noopener");
    },
    className: ` button--social-media ${
      (type && types.get(type)) || types.values().next().value
    } ${className ? className : ""}`,
  };
  return <button {...values} />;
};

export default MultiButton;
