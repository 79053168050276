import * as React from "react";
import "./toggleButton.style.css";

interface IProps {
  on?: boolean;
  className?: string;
  onClick?: (isOn: boolean) => void;
}

const ToggleButton = (props: IProps) => {
  const { className = "", on = false, onClick } = props;

  return (
    <button
      onClick={() => {
        if (onClick) {
          onClick(!on);
        }
      }}
      className={`toggleButton ${on ? "-on" : "-off"} ${className}`}
    />
  );
};

export default ToggleButton;
