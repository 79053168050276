import * as React from "react";
import "./responsiveList.style.css";

interface IProps {
  className?: string;
  children: any;
}

const ResponsiveList = (props: IProps) => {
  const { className = "", children } = props;
  return <ul className={`responsiveList ${className}`}>{children}</ul>;
};

export default ResponsiveList;
