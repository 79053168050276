import { Entry, Locale, LocaleCollection } from "contentful";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import Title from "../../components/Title";
import { IFooter } from "../../contentfulTypes";
import { AppContext } from "../../context";
import { client, getCachedQuery } from "../../util/contentfulQueries";
import { ResponsiveList, TickListItem } from "../List";
import "./languageSwitcher.style.css";

interface INavigationState {
  locales: LocaleCollection | null;
  data: Entry<IFooter> | null;
}

interface IProps extends WithTranslation {
  history: any;
  className?: string;
  buttonId?: string;
  divId?: string;
  mobile?: boolean;
}

class LanguageSwitcher extends React.Component<IProps, INavigationState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      locales: null,
      data: null,
    };
    this.setLocales();
    this.getData(props.i18n.language);
  }

  // Needed, because after language switch this component stays mounted and
  // data needs to be refetched in the correct language.
  public componentDidUpdate() {
    if (
      !this.state.data ||
      this.state.data.sys.locale !== this.props.i18n.language
    ) {
      this.getData(this.props.i18n.language);
    }
  }

  public getData = async (language: string) => {
    const footerData = await getCachedQuery<IFooter>(
      {
        content_type: "footer",
        limit: 1,
        include: 2,
      },
      language
    );

    const data =
      footerData &&
      footerData.items &&
      footerData.items.length > 0 &&
      footerData.items[0];
    if (data) {
      this.setState({ data });
    }
  };

  public async setLocales() {
    if (!this.state.locales) {
      const locales = await client.getLocales();
      this.setState({ locales });
    }
  }

  public translateLocaleName(name: string) {
    const lowerCaseName = name.toLowerCase();
    if (lowerCaseName.startsWith("english") || lowerCaseName === "en") {
      return "English";
    }
    if (lowerCaseName.startsWith("spanish") || lowerCaseName === "es") {
      return "Español";
    }
    if (lowerCaseName.startsWith("dutch") || lowerCaseName === "nl") {
      return "Nederlands";
    }
    if (lowerCaseName.startsWith("french") || lowerCaseName === "fr") {
      return "Français";
    }
    if (lowerCaseName.startsWith("german") || lowerCaseName === "de") {
      return "Deutsch";
    }
    if (lowerCaseName.startsWith("portuguese") || lowerCaseName === "pt") {
      return "Português";
    }
    return name;
  }

  public async handleLanguageSwitch(locale: Locale) {
    // delete content previous language because of sessionStorage limits
    this.deleteOldLanguage(locale.code);

    // change the language
    await this.props.i18n.changeLanguage(locale.code);
    this.props.history.push(`/${locale.code}/`);
  }

  public async deleteOldLanguage(locale: string) {
    Object.keys(window.sessionStorage).forEach((key) => {
      const keyJSON = JSON.parse(key);
      if (
        keyJSON.locale &&
        keyJSON.locale !== locale &&
        keyJSON.locale !== "*"
      ) {
        window.sessionStorage.removeItem(key);
      }
    });
  }

  public render() {
    if (!this.state.data) {
      return null;
    }
    const locale = this.props.i18n.language;
    // const footerData = this.state.data;
    return (
      <div
        className={
          this.props.className ? this.props.className : "footer__language"
        }
        id={this.props.divId ? this.props.divId : ""}
      >
        <Title
          heading="h3"
          expandWithFilter
          text={
            this.props.mobile
              ? this.translateLocaleName(locale)
              : locale.toUpperCase()
          }
          buttonId={this.props.buttonId}
          dropdown={(handleToggle: any) => (
            <ResponsiveList>
              {this.state.locales &&
                this.state.locales.items
                  .sort((a, b) =>
                    this.translateLocaleName(a.name) >
                    this.translateLocaleName(b.name)
                      ? 1
                      : -1
                  )
                  .map((loc, i) => (
                    <TickListItem
                      key={i}
                      text={this.translateLocaleName(loc.name)}
                      ticked={locale === loc.code}
                      onClick={(event: any) => {
                        handleToggle(event);
                        this.handleLanguageSwitch(loc);
                      }}
                      id={loc.code}
                    />
                  ))}
            </ResponsiveList>
          )}
        />
      </div>
    );
  }
}

LanguageSwitcher.contextType = AppContext;

export default withTranslation()(LanguageSwitcher);
