import { Entry } from "contentful";
import * as React from "react";
import {
  IArticle,
  ICategory,
  IExternalNavigationLink,
  IInternalNavigationLink,
  IPage,
  IQuestion,
} from "../../contentfulTypes";
import LocaleNavigationLink from "../LocaleNavigationLink";

interface IProps {
  onClick?: (event: any) => void;
  link: Entry<IInternalNavigationLink | IExternalNavigationLink>;
}

const NavigationLink = ({ link, onClick }: IProps) => {
  if (!link.fields) {
    return null;
  }

  if (typeof link.fields.link === "string") {
    return (
      <a
        href={link.fields.link}
        onClick={onClick}
        target={
          link.fields.link.substring(0, 24) === "https://www.curacao.com/"
            ? "_top"
            : "_blank"
        }
      >
        {link.fields.title}
      </a>
    );
  }

  const page = link.fields.link as Entry<
    IPage | IArticle | IQuestion | ICategory
  >;
  const getUrl = (
    item: Entry<IPage | IArticle | IQuestion | ICategory>
  ): string => {
    switch (item.sys.contentType.sys.id) {
      case "page":
        return item.fields.slug || "";
      case "article":
        return `/article/${item.fields.slug}`;
      case "question":
        const itemTypeQuestion = item as Entry<IQuestion>;
        // TODO: find EFFICIENT solution for missing category slug
        const qToUrl = itemTypeQuestion.fields.category.fields
          ? `/questions/${itemTypeQuestion.fields.category.fields.slug}/${itemTypeQuestion.fields.slug}`
          : "/questions/";
        return qToUrl;
      case "category":
        return `/category/${item.fields.slug}`;
      default:
        return "/";
    }
  };
  // console.log(getUrl(page));

  return (
    <LocaleNavigationLink to={getUrl(page)} onClick={onClick}>
      {link.fields.title}
    </LocaleNavigationLink>
  );
};

export default NavigationLink;
