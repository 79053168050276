import * as React from "react";
import Label from "../Label/Label";
import "./selectfake.style.css";

interface IProps {
  className?: string;
  value?: string;
  valueList: string[];
  longList?: boolean;
  searchable?: boolean;
  placeholder?: string;
  title?: string;
  icon?: boolean | string;
  errorMessage?: string | boolean;
  onChange?: any;
  style?: "default" | "transparent";
  name: string;
}

interface IState {
  toggle: boolean;
  valueList: string[];
  value: string | undefined;
}

class SelectFake extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      toggle: false,
      valueList: this.props.valueList,
      value: undefined,
    };
  }

  public async setFieldValue(event: any, val: string) {
    event.preventDefault();
    const e = {
      bogus: null,
      target: this.refs.inputField,
    };
    (this.refs.inputField as HTMLInputElement).value = val;
    await this.setState({
      value: val,
      toggle: this.props.valueList.includes(val) ? false : true,
    });
    await this.props.onChange(e);
  }

  public render() {
    return (
      <Label
        text={this.props.title}
        icon={this.props.icon}
        errorMessage={this.props.errorMessage}
        className={`selectFake ${this.props.longList ? "longlist" : ""}`}
      >
        <input
          ref="inputField"
          className={`selectFake__input -${this.props.style} ${this.props.className}`}
          type="text"
          placeholder={this.props.placeholder}
          name={this.props.name}
          onChange={(inp) => {
            this.setState({
              valueList: this.props.valueList.filter((s) =>
                s.toLowerCase().includes(inp.target.value.toLowerCase())
              ),
            });
            this.setFieldValue(inp, inp.target.value);
          }}
          onFocus={() => this.setState({ toggle: true })}
          onBlur={() => this.setState({ toggle: false })}
          autoComplete="new-password"
        />
        <div
          className={`selectFake__overlay ${this.state.toggle ? "" : "hidden"}`}
        >
          {this.state.valueList &&
            this.state.valueList.map((item, i) => {
              return (
                <div key={i} onClick={(e) => this.setFieldValue(e, item)}>
                  {item}
                </div>
              );
            })}
        </div>
      </Label>
    );
  }
}

export default SelectFake;
