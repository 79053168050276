import * as React from "react";
import LocaleNavigationLink from "../../LocaleNavigationLink";
import "./multiButton.style.css";

interface IMultiButtonProps {
  onClick?: any;
  text?: string;
  className?: string;
  to?: string;
  type?: string;
}

const types = new Map([
  ["basic", "button--basic"],
  ["important", "button--important"],
  ["important-alt", "button--important-alt"],
  ["card", "button--ed-card"],
  ["light", "button--light"],
]);

const MultiButton = (props: IMultiButtonProps) => {
  const {
    text = `${props.type ? props.type : types.values().next().value} Button`,
    onClick,
    className,
    to,
    type,
  } = props;
  const values = {
    onClick,
    className: `
    ${(type && types.get(type)) || types.values().next().value} 
    ${className ? className : ""}`,
  };
  if (!to) {
    return (
      <button {...values}>
        <h6>{text}</h6>
      </button>
    );
  } else {
    return (
      <LocaleNavigationLink {...values} to={to}>
        <h6>{text}</h6>
      </LocaleNavigationLink>
    );
  }
};

export default MultiButton;
