import { Asset } from "contentful";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
// @ts-ignore
import TextTruncate from "react-text-truncate";
import { AppContext, IAppInterface } from "../../context";
import LocaleNavigationLink from "../LocaleNavigationLink";
import PictureWrapper from "../PictureWrapper";
import { FavouritesSVG, TripAdvisorReviewWhiteSVG } from "../SVG";
import "./card.style.css";

interface IProps extends WithTranslation {
  title: string;
  description?: string;
  to: string;
  image: Asset;
  className?: string;
  square?: boolean;
  hoverBackground?: boolean;
  imgFade?: boolean;
  subtitle?: string;
  subtitleLink?: string;
  imgBottomContent?: any;
  disabled?: boolean;
  imgTopContent?: any;
  aboveContent?: any;
  favourite?: string;
  tripAdvisorRating?: number;
  tripAdvisorReviewCount?: number;
  onClick?: () => void;
  onFavourite?: (value: boolean) => void;
  tripAdvisorPosition?: "top" | "bottom";
}

const Card = (props: IProps) => {
  const {
    title,
    description,
    image,
    className = "",
    to,
    square = false,
    hoverBackground = true,
    imgFade = false,
    subtitle = false,
    imgBottomContent = false,
    disabled = false,
    imgTopContent = false,
    aboveContent = false,
    favourite,
    tripAdvisorRating,
    tripAdvisorReviewCount,
    onFavourite,
    onClick,
    tripAdvisorPosition = "top",
  } = props;

  return (
    <AppContext.Consumer>
      {({ addFavourite, removeFavourite, favouritesList }: IAppInterface) => {
        const isFavourite = favourite && favouritesList.includes(favourite);
        return (
          <LocaleNavigationLink
            className={`card ${hoverBackground ? "-hover-background" : ""} ${
              square ? "-square" : ""
            } ${disabled ? "-disabled" : ""} ${className}`}
            to={to}
            onClick={onClick}
          >
            <div className="card__above-content">{aboveContent}</div>
            <div className={`card__img-container`}>
              <PictureWrapper
                className="card__img"
                small={square ? [250, 250] : [620, 435]}
                medium={square ? [250, 250] : [380, 264]}
                large={square ? [250, 250] : [350, 245]}
                asset={image}
                options={[["fit", "fill"]]}
              />
              <div className={`card__img-top-content`}>{imgTopContent}</div>
              <div
                className={`card__img-bottom-content ${
                  imgFade ||
                  (tripAdvisorRating && tripAdvisorPosition === "top")
                    ? "-fade"
                    : ""
                }`}
              >
                {imgBottomContent}
                {tripAdvisorRating && tripAdvisorPosition === "top" && (
                  <div className="card__card-rating-top">
                    <TripAdvisorReviewWhiteSVG
                      count={tripAdvisorRating}
                      className="card__trip-advisor-svg"
                    />
                    {tripAdvisorReviewCount &&
                      props.i18n.t("tripAdvisorReviews", {
                        value: tripAdvisorReviewCount,
                      })}
                  </div>
                )}
              </div>
            </div>
            <div className="card__caption">
              <h4 className="card__title">{title}</h4>
              {subtitle && <h6 className="card__subtitle">{subtitle}</h6>}
              {description && (
                <TextTruncate
                  line={2}
                  text={description}
                  truncateText="…"
                  className="body--2 card__description"
                />
              )}
            </div>
            {favourite && (
              <FavouritesSVG
                className="card__favourites"
                onClick={(ev: any) => {
                  if (onFavourite) {
                    onFavourite(!isFavourite);
                  }
                  if (isFavourite) {
                    removeFavourite(favourite);
                  } else {
                    addFavourite(favourite, "/" + props.i18n.language + to);
                  }
                  ev.stopPropagation();
                  ev.preventDefault();
                }}
                style={isFavourite ? "active" : "grey"}
              />
            )}
            {tripAdvisorRating && tripAdvisorPosition === "bottom" && (
              <>
                <div className="card__card-rating-bottom">
                  <img
                    className="card__card-rating"
                    alt={`Trip advisor rating ${tripAdvisorRating.toFixed(1)}`}
                    src={`https://www.tripadvisor.com/img/cdsi/img2/ratings/traveler/${tripAdvisorRating.toFixed(
                      1
                    )}-MCID-5.svg`}
                  />
                  {tripAdvisorReviewCount &&
                    tripAdvisorReviewCount + " " + props.i18n.t("reviews")}
                </div>
              </>
            )}
          </LocaleNavigationLink>
        );
      }}
    </AppContext.Consumer>
  );
};

export default withTranslation()(Card);
