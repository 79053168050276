import * as React from "react";
import "./favourites.style.css";

interface IProps {
  className?: string;
  style?: string;
  onClick?: (ev?: any) => void;
}

const styles = new Map([
  ["grey", "-grey"],
  ["disabled", "-disabled"],
  ["active", "-active"],
  ["blue", "-blue"],
  ["blue-active", "-blue-active"],
]);

const Favourites = (props: IProps) => {
  const { className = "", style, onClick } = props;
  return (
    <svg
      className={`favouritesSVG ${
        style && styles.get(style)
          ? styles.get(style)
          : styles.values().next().value
      } ${className}`}
      onClick={onClick && onClick}
      viewBox="0 0 65 56"
      preserveAspectRatio="xMidYMid meet"
    >
      <g fill="none" fillRule="evenodd">
        <path
          fillRule="nonzero"
          className="favouritesSVG__inside"
          // tslint:disable-next-line:max-line-length
          d="M5.87993111,5.87449226 C-0.63084144,12.3910378 -0.622444611,22.8332389 5.87993111,29.3541396 L30.7322865,54.2815788 C31.1894621,54.7403659 31.8231922,55 32.4858513,55 C33.1485103,55 33.7822404,54.7403659 34.2394161,54.2815788 C42.5297511,45.9836293 50.8265453,37.6762589 59.1170418,29.3782322 C65.6276527,22.8615785 65.6276527,12.4152771 59.1170418,5.89862341 C52.6063502,-0.618007108 41.8602654,-0.618146104 35.3495738,5.89862341 L32.4984465,8.72196794 L29.6473999,5.87449226 C26.3920137,2.61616928 22.0779004,1 17.7636256,1 C13.4493507,1 9.13523737,2.61615384 5.87993111,5.87449226 Z"
        />
        <path
          className="favouritesSVG__border"
          fillRule="nonzero"
          // tslint:disable-next-line:max-line-length
          d="M17.7636256,1 C13.4493507,1 9.13523737,2.61615384 5.87993111,5.87449226 C-0.63084144,12.3910378 -0.622444611,22.8332389 5.87993111,29.3541396 L30.7322865,54.2815788 C31.1894621,54.7403659 31.8231922,55 32.4858513,55 C33.1485103,55 33.7822404,54.7403659 34.2394161,54.2815788 C42.5297511,45.9836293 50.8265453,37.6762589 59.1170418,29.3782322 C65.6276527,22.8615785 65.6276527,12.4152771 59.1170418,5.89862341 C52.6063502,-0.618007108 41.8602654,-0.618146104 35.3495738,5.89862341 L32.4984465,8.72196794 L29.6473999,5.87449226 C26.3920137,2.61616928 22.0779004,1 17.7636256,1 Z M17.7636256,5.53665616 C20.7752615,5.53665616 23.8073243,6.72358441 26.1655416,9.08393519 L30.7575577,13.6929771 C31.2147309,14.1517396 31.8484434,14.4113578 32.5110821,14.4113578 C33.1737208,14.4113578 33.8074333,14.1517396 34.2646065,13.6929771 L38.8314322,9.10806634 C43.5477859,4.38727212 50.8935584,4.38738023 55.6099122,9.10806634 C60.326266,13.8287293 60.326266,21.472242 55.6099122,26.1929204 C47.9072745,33.9026876 40.2010842,41.6008719 32.4984465,49.3105619 L9.38698078,26.1688201 C4.67312909,21.4415086 4.67062619,13.8044823 9.38698078,9.08393519 C11.7451173,6.72359986 14.7519896,5.53665616 17.7636256,5.53665616 Z"
        />
      </g>
    </svg>
  );
};

export default Favourites;
