import * as React from "react";
import RichText from "../../RichText";
import "./checkbox.style.css";

interface IProps {
  className?: string;
  text?: string | any;
  onChange: (ev: any) => void;
  checked?: boolean;
  name: string;
  style?: "default" | "white";
  errorMessage: string | boolean;
  history?: any;
}

const Checkbox = (props: IProps) => {
  const {
    className = "",
    text,
    style = "default",
    checked = false,
    onChange,
    name,
    errorMessage,
    history,
  } = props;
  const isRichText = typeof text === "object";
  return (
    <div className={`checkbox body--1 ${className}`}>
      <input
        onChange={onChange}
        checked={checked}
        aria-checked={checked}
        type="checkbox"
        name={name}
        className={`checkbox__box -${style} ${className}`}
        id={name}
      />
      <label className="checkbox__label" htmlFor={name} aria-label={name}>
        {isRichText && history ? (
          <RichText history={history} data={text} type="white" />
        ) : (
          text
        )}
      </label>
      <div className="body--2 checkbox__error">{errorMessage}</div>
    </div>
  );
};

export default Checkbox;
