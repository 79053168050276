import { Asset, Entry } from "contentful";
import * as React from "react";
import {
  // useTranslation,
  withTranslation,
  WithTranslation,
} from "react-i18next";
import { MultiButton } from "../../components/Buttons";
import LocaleNavigationLink from "../../components/LocaleNavigationLink";
import NavigationLink from "../../components/NavigationLink";
import {
  ICategory,
  IInternalNavigationLink,
  IExternalNavigationLink,
  IQuestionCategory,
} from "../../contentfulTypes";
import { getCachedQuery } from "../../util/contentfulQueries";
import PictureWrapper from "../../components/PictureWrapper";

export type SubMenuType =
  | "Activity categories"
  | "Accommodation categories"
  | "Question categories"
  | "Itineraries"
  | "Custom links";

interface IProps extends WithTranslation {
  type: SubMenuType;
  baseLink: string;
  baseImage?: Asset;
  links?: Array<Entry<IInternalNavigationLink | IExternalNavigationLink>>;
  active?: boolean;
  onClick?: (event: any) => void;
}

interface IState {
  data: Array<Entry<ICategory | IQuestionCategory>>;
  linksData: Array<Entry<IInternalNavigationLink | IExternalNavigationLink>>;
}

const types = new Map<SubMenuType, CategorySystemName>([
  ["Activity categories", "category"],
  ["Accommodation categories", "category"],
  ["Itineraries", "itinerary"],
  ["Question categories", "questionCategory"],
]);

const codeToLang = {
  en: "English",
  nl: "Dutch",
  fr: "French",
  de: "German",
  pt: "Portuguese",
  es: "Spanish",
};

// TODO: move to separate file
export type CategorySystemName = "category" | "itinerary" | "questionCategory";

// TODO: move to separate util class
export const getCategoryUrl = (linkType: CategorySystemName, slug: string) => {
  if (linkType === "category") {
    return `/category/${slug}`;
  }
  if (linkType === "questionCategory") {
    return `/questions/${slug}`;
  }
  if (linkType === "itinerary") {
    return `/itinerary/${slug}`;
  }
  return slug;
};

export const getCategorySort = (linkType: CategorySystemName) => {
  if (linkType === "itinerary") {
    return (a: any, b: any) =>
      a.fields.itineraryDays.length - b.fields.itineraryDays.length;
    // removed :
    /*
      a.fields.itineraryDays &&
      a.fields.itineraryDays.length - b.fields.itineraryDays &&
      b.fields.itineraryDays.length;
	*/
  }
  return undefined;
};

class NavigationSubMenu extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      data: [],
      linksData: [],
    };
    this.getData(props.i18n.language);
  }

  // Needed, because after language switch this component stays mounted and
  // data needs to be refetched in the correct language.
  public componentDidUpdate() {
    if (
      !this.state.data ||
      (this.state.data.length > 0 &&
        this.state.data[0].sys.locale !== this.props.i18n.language) ||
      !this.state.linksData ||
      (this.state.linksData.length > 0 &&
        this.state.linksData[0].sys.locale !== this.props.i18n.language)
    ) {
      this.getData(this.props.i18n.language);
    }
  }

  public getData = async (language: string) => {
    if (this.props.links) {
      const data = await getCachedQuery<
        IInternalNavigationLink | IExternalNavigationLink
      >(this.getContentfulQuery(), language);
      if (data) {
        const linksData0: Entry<
          IInternalNavigationLink | IExternalNavigationLink
        >[] = [];
        // eslint-disable-next-line
        this.props.links.map((item, i) => {
          // eslint-disable-next-line
          data.items.map((item1, i1) => {
            if (item1.sys.id === item.sys.id) {
              linksData0.push(item1);
            }
          });
        });
        this.setState({ linksData: linksData0 });
      }
    } else {
      const data = await getCachedQuery<ICategory | IQuestionCategory>(
        this.getContentfulQuery(),
        language
      );
      if (data) {
        const dataItems = data.items
          .filter((item, i) => {
            if (
              // filter out categories with showSubmenu false
              !("showSubmenu" in item.fields) ||
              item.fields.showSubmenu === undefined ||
              item.fields.showSubmenu === true
            ) {
              return true;
            } else return false;
          })
          .filter((item, i) => {
            return (
              // filter out question categories we don't want to show in this language
              !("showInLanguage" in item.fields) ||
              !item.fields.showInLanguage ||
              item.fields.showInLanguage.includes(
                // @ts-ignore
                codeToLang[this.props.i18n.language]
              )
            );
          });
        this.setState({ data: dataItems });
      }
    }
  };

  public getContentfulQuery = () => {
    let contentfulQuery = {};
    switch (this.props.type) {
      case "Activity categories":
        contentfulQuery = {
          content_type: "category",
          "fields.type[match]": "Activity category",
          limit: 20,
          include: 2,
          order: "fields.name",
        };
        break;
      case "Accommodation categories":
        contentfulQuery = {
          content_type: "category",
          "fields.type[match]": "Accommodation category",
          limit: 20,
          include: 2,
          order: "fields.name",
        };
        break;
      case "Itineraries":
        contentfulQuery = {
          content_type: "itinerary",
          limit: 20,
          include: 0,
        };
        break;
      case "Question categories":
        contentfulQuery = {
          content_type: "questionCategory",
          limit: 20,
          include: 2,
          order: "fields.sortOrder",
        };
        break;
      case "Custom links":
        if (this.props.links) {
          const customLinks = this.props.links.map((item, i) => {
            return item.sys.id;
          });
          contentfulQuery = {
            "sys.id[in]": customLinks.join(","),
            limit: 20,
            include: 1,
          };
        }
        break;
      default:
        return {};
    }
    return contentfulQuery;
  };

  public render() {
    const { type, baseLink, baseImage, links, active = false } = this.props;
    const data = this.state.data;
    const linksData = this.state.linksData;

    if ((!data || data.length === 0) && !links) {
      return null;
    }

    const columnSize =
      !data || data.length < 6 ? 1 : data.length <= 100 ? 2 : 3;
    const contentType = (
      types.has(type) ? types.get(type) : "activityCategory"
    ) as CategorySystemName;

    return (
      <div
        aria-label="main navigation sub-menu"
        className={`
                    nav__sub-menu 
                    
										${links ? "-no-button" : ""}
                    ${active ? "--active" : ""}
                    ${!data ? "-no-content" : ""}
                    `}
      >
        <div className={`nav__sub-menu-container `}>
          {baseImage && (
            <PictureWrapper
              className={`nav__sub-menu-img`}
              options={[["fit", "fill"]]}
              small={[690, 480]}
              medium={[600, 415]}
              large={[690, 480]}
              asset={baseImage}
            />
          )}
          <ul className={`-column-${columnSize}`}>
            {!links &&
              data
                .sort(getCategorySort(contentType))
                .map(
                  (
                    subNavItem: Entry<ICategory | IQuestionCategory>,
                    key2: number
                  ) => (
                    <React.Fragment key={key2}>
                      <li className="nav__sub-item">
                        <LocaleNavigationLink
                          to={getCategoryUrl(
                            contentType,
                            subNavItem.fields.slug
                          )}
                          baseLink={baseLink}
                          onClick={this.props.onClick}
                          className="submenu"
                        >
                          {subNavItem.fields.name}
                        </LocaleNavigationLink>
                      </li>
                    </React.Fragment>
                  )
                )}
            {links &&
              linksData &&
              linksData.map(
                (
                  link: Entry<
                    IInternalNavigationLink | IExternalNavigationLink
                  >,
                  key2: number
                ) =>
                  link.sys.contentType.sys.id === "externalNavigationLink" ||
                  typeof link.fields.link === "string" ? (
                    <li className="nav__sub-item" key={key2}>
                      <NavigationLink
                        link={link as any}
                        onClick={this.props.onClick}
                      />
                    </li>
                  ) : (
                    <li className="nav__sub-item" key={key2}>
                      <LocaleNavigationLink
                        to={
                          link.fields.link.sys.contentType.sys.id === "article"
                            ? "/article/" + link.fields.link.fields.slug
                            : link.fields.link.sys.contentType.sys.id === "page"
                            ? link.fields.link.fields.slug
                            : "/"
                        }
                        baseLink={baseLink}
                        className="submenu"
                        onClick={this.props.onClick}
                      >
                        {link.fields.title}
                      </LocaleNavigationLink>
                    </li>
                  )
              )}
            {false && !links && (
              <MultiButton
                text={this.props.t("viewAll")}
                className="nav__sub-btn"
                to={baseLink}
                type="basic"
              />
            )}
          </ul>
        </div>
      </div>
    );
  }
}

export default withTranslation()(NavigationSubMenu);
