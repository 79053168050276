import * as React from "react";
import TickIcon from "../../../Assets/Checkmarks/SVG/contact-success-checkmark.svg";
import ErrorIcon from "../../../Assets/Error States/SVG/error-submitting.svg";
import "./formMessage.style.css";

interface IProps {
  className?: string;
  icon?: string;
  text?: string;
  subtext?: string;
}

const FormMessage = (props: IProps) => {
  const { className = "", icon, text, subtext } = props;
  return (
    <div className={`formMessage ${className}`}>
      <img
        alt=""
        role="presentation"
        src={icon !== "error" ? TickIcon : ErrorIcon}
        className="formMessage__img"
      />
      <h3 className="formMessage__text">{text}</h3>
      <p className="body--2 formMessage__subtext">{subtext}</p>
    </div>
  );
};

export default FormMessage;
