import * as React from "react";
import "./singleColumnList.style.css";

interface IProps {
  className?: string;
  children: any;
  noMargin?: boolean;
}

const SingleColumnList = (props: IProps) => {
  const { className = "", children, noMargin = false } = props;
  return (
    <ul
      className={`singleColumnList ${
        noMargin ? "-no-margin" : ""
      } ${className}`}
    >
      {children}
    </ul>
  );
};

export default SingleColumnList;
