// @ts-ignore
import { EntryCollection } from "contentful";
import * as React from "react";
import ReactGA from "react-ga";
// import ReactGA4 from "react-ga4";
import { withTranslation, WithTranslation } from "react-i18next";
import Loadable from "react-loadable";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";

import Footer from "../../components/Footer/Footer";
import GoogleTagManager from "../../components/GoogleTagManager";
import Loader from "../../components/Loader";
import WhatsappChat from "../../components/WhatsappChat";
import Favourites from "../../containers/Favourites";
import GdprConsent from "../../containers/GdprConsent";
import Navigation from "../../containers/Navigation";
import {
  ISiteConfig,
  INavigation,
  IInternalNavigationLink,
  IExternalNavigationLink,
  ICategory,
  IPage,
} from "../../contentfulTypes";
import { AppContext, defaultAppData, IAppInterface } from "../../context";
import { getCachedQuery } from "../../util/contentfulQueries";
import customGA from "../../util/customGA";
import "./root.style.css";

const AsyncItem = Loadable({
  loader: () => import("../Item"),
  loading: Loader,
});

const AsyncCategory = Loadable({
  loader: () => import("../Category"),
  loading: Loader,
});

const AsyncSaved = Loadable({
  loader: () => import("../Saved"),
  loading: Loader,
});

const AsyncInteractiveMap = Loadable({
  loader: () => import("../InteractiveMap"),
  loading: Loader,
});

const AsyncItinerary = Loadable({
  loader: () => import("../Itinerary"),
  loading: Loader,
});

const AsyncUpgradeBrowser = Loadable({
  loader: () => import("../UpgradeBrowser"),
  loading: Loader,
});

const AsyncArticle = Loadable({
  loader: () => import("../Article"),
  loading: Loader,
});

const AsyncPage = Loadable({
  loader: () => import("../Page"),
  loading: Loader,
});

const AsyncQuestionContainer = Loadable({
  loader: () => import("../QuestionContainer"),
  loading: Loader,
});

const AsyncQuestionSearch = Loadable({
  loader: () => import("../QuestionSearch"),
  loading: Loader,
});

const AsyncNotFound = Loadable({
  loader: () => import("../NotFound"),
  loading: Loader,
});

interface IState {
  appContext: IAppInterface;
  pathName: string;
  isIE: string;
}

interface IProps extends WithTranslation, RouteComponentProps<{}> {}

class Root extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);

    this.setNavigationType = this.setNavigationType.bind(this);
    this.setTreeNavigation = this.setTreeNavigation.bind(this);
    this.setFavourites = this.setFavourites.bind(this);
    this.addFavourite = this.addFavourite.bind(this);
    this.removeFavourite = this.removeFavourite.bind(this);
    this.setHasAskedForGdprConsent = this.setHasAskedForGdprConsent.bind(this);
    this.setHasGdprConsent = this.setHasGdprConsent.bind(this);

    const favList = window.localStorage.getItem("favourites");

    this.state = {
      appContext: {
        ...defaultAppData,
        setNavigationType: this.setNavigationType,
        setTreeNavigation: this.setTreeNavigation,
        siteConfig: false,
        isFavouritesOpen: false,
        setFavourites: this.setFavourites,
        addFavourite: this.addFavourite,
        removeFavourite: this.removeFavourite,
        favouritesList: favList ? JSON.parse(favList) : [],
        setHasAskedForGdprConsent: this.setHasAskedForGdprConsent,
        hasAskedForGdprConsent:
          window.localStorage.getItem("gdprConsent") === "1" ||
          window.localStorage.getItem("gdprConsent") === "0",
        setHasGdprConsent: this.setHasGdprConsent,
        hasGdprConsent: window.localStorage.getItem("gdprConsent") === "1",
        referrerUrl: document.referrer,
        treeNavigation: [],
        typeNavigation: [],
        subSelected: "",
        mainSelected: "",
      },
      pathName: this.props.location.pathname,
      isIE: "",
    };

    const language = props.i18n.language;
    const languageInUrl = props.location.pathname.substring(
      1,
      props.location.pathname.substring(1).indexOf("/") !== -1
        ? props.location.pathname.substring(1).indexOf("/") + 1
        : props.location.pathname.substring(1).length + 1
    );
    if (language !== languageInUrl && props.location.pathname.length === 3) {
      window.history.pushState(null, "", props.location.pathname + "/");
    }

    if (languageInUrl !== "" && language !== languageInUrl) {
      if (props.i18n.hasResourceBundle(languageInUrl, "translations")) {
        props.i18n.changeLanguage(languageInUrl);
      } else {
        props.i18n.changeLanguage("en");
        props.history.push(`/${props.i18n.language}/`);
      }
    }

    if (
      (languageInUrl !== "" &&
        !props.i18n.hasResourceBundle(languageInUrl, "translations")) ||
      (languageInUrl === "" &&
        !props.i18n.hasResourceBundle(language, "translations"))
    ) {
      props.i18n.changeLanguage("en");
      props.history.push(`/${props.i18n.language}/`);
    }

    this.getSiteConfig(props.i18n.language);
  }

  public getSiteConfig = async (language: string) => {
    const data = await getCachedQuery<ISiteConfig>(
      {
        content_type: "siteConfig",
        limit: 1,
        include: 2,
      },
      language
    );

    if (data && data.items.length > 0) {
      this.setState({
        appContext: { ...this.state.appContext, siteConfig: data.items[0] },
      });

      return data.items[0];
    }
    return false;
  };

  public setNavigationType(navigationType: 1 | 2 | 3) {
    if (this.state.appContext.navigationType !== navigationType) {
      // Dirty fix: execure setState in a setTimeout, else state transition during render function
      // is done and browser console error shown.
      setTimeout(
        () =>
          this.setState({
            appContext: {
              ...this.state.appContext,
              navigationType,
            },
          }),
        200
      );
    }
  }

  public setTreeNavigation(treeClues: any) {
    const tree = this.state.appContext.treeNavigation;
    let mainItem: string = "";
    let subItem: string = "";

    if (treeClues.pageType && !treeClues.slug) {
      if (treeClues.subType) {
        if (
          treeClues.pageType === "activity" ||
          treeClues.pageType === "accommodation"
        ) {
          treeClues.slug = treeClues.subType;
        }
      } else if (this.state.appContext.typeNavigation[treeClues.pageType]) {
        treeClues.slug =
          this.state.appContext.typeNavigation[treeClues.pageType];
      }
    }
    const mainItems: string[] = [];
    const subItems: string[] = [];
    Object.keys(tree).forEach((key) => {
      // @ts-ignore
      Object.keys(tree[key]).forEach((leaf) => {
        // @ts-ignore
        if (treeClues.slug === tree[key][leaf]) {
          // @ts-ignore
          subItems.push(tree[key][leaf]);
          mainItems.push(key);
        }
      });
      if (treeClues.slug === key) {
        mainItems.push(key);
      }
    });
    // deal with cat & questioncat having same slug
    if (subItems.length > 1) {
      if (treeClues.pageType) {
        mainItems.forEach((key, i) => {
          if (
            key === this.state.appContext.typeNavigation[treeClues.pageType]
          ) {
            subItem = subItems[i];
            mainItem = mainItems[i];
          }
        });
      }
      if (subItem === "") {
        subItem = subItems[0];
        mainItem = mainItems[0];
      }
    } else if (subItems.length === 1) {
      subItem = subItems[0];
      mainItem = mainItems[0];
    } else if (mainItems.length >= 1) {
      mainItem = mainItems[0];
    }
    if (
      this.state.appContext.subSelected !== subItem ||
      this.state.appContext.mainSelected !== mainItem
    ) {
      // dirty fix, see above
      setTimeout(
        () =>
          this.setState({
            appContext: {
              ...this.state.appContext,
              subSelected: subItem,
              mainSelected: mainItem,
            },
          }),
        200
      );
    }
  }

  public async getTypeNavigation(siteConfig: any) {
    if (!siteConfig) {
      siteConfig = await this.getSiteConfig(this.props.i18n.language);
    }
    const typeToSlug: any = {};
    typeToSlug.activity =
      siteConfig.fields.activityRootLink.fields.link.fields.slug;
    typeToSlug.accommodation =
      siteConfig.fields.accommodationRootLink.fields.link.fields.slug;
    typeToSlug.event = siteConfig.fields.eventRootLink.fields.link.fields.slug;
    typeToSlug.question =
      siteConfig.fields.questionRootLink.fields.link.fields.slug;
    const itinQuery = {
      content_type: "page",
      "fields.itinerariesPage": 1,
      limit: 1,
      include: 1,
    };
    const itinPage = await getCachedQuery<IPage>(
      itinQuery,
      this.props.i18n.language
    );
    if (itinPage && itinPage.items.length) {
      typeToSlug.itinerary = itinPage.items[0].fields.slug;
    }
    const articlesQuery = {
      content_type: "page",
      "fields.articlesPage": 1,
      limit: 1,
      include: 1,
    };
    const articlesPage = await getCachedQuery<IPage>(
      articlesQuery,
      this.props.i18n.language
    );
    if (articlesPage && articlesPage.items.length) {
      typeToSlug.article = articlesPage.items[0].fields.slug;
    }

    setTimeout(
      () =>
        this.setState({
          appContext: {
            ...this.state.appContext,
            typeNavigation: typeToSlug,
          },
        }),
      1
    );
    return typeToSlug;
  }

  public async getTreeNavigation() {
    // const typeToSlug = await this.getTypeNavigation(
    //  this.state.appContext.siteConfig
    // );

    const tree: string[] = [];
    const data: EntryCollection<INavigation> | null =
      await getCachedQuery<INavigation>(
        {
          content_type: "navigation",
          limit: 1,
          include: 2,
        },
        this.props.i18n.language
      );
    const mainMenu: any = data && data.items.length ? data.items[0] : [];
    const fullMenu = mainMenu.fields.leftLinks.concat(
      mainMenu.fields.rightLinks
    );
    if (fullMenu) {
      await fullMenu.map(async (item: any, i: number) => {
        const mainSlug: string =
          typeof item.fields.link === "string"
            ? "externalLink"
            : item.fields.link.fields.slug;
        // @ts-ignore
        tree[mainSlug] = [];
        if (item.fields.subnavigationLinks) {
          const customLinks = item.fields.subnavigationLinks.map(
            (el: any, j: number) => {
              return el.sys.id;
            }
          );
          const dataLinks = await getCachedQuery<
            IInternalNavigationLink | IExternalNavigationLink
          >(
            {
              "sys.id[in]": customLinks.join(","),
              limit: 20,
              include: 1,
            },
            this.props.i18n.language
          );
          if (dataLinks) {
            const nDataLinks = dataLinks.items.filter(
              (el) => typeof el.fields.link !== "string"
            );
            // @ts-ignore
            tree[mainSlug] = nDataLinks.map((el: any, j: number) => {
              return el.fields.link.fields.slug;
            });
          }
        } else if (item.fields.subNavigation) {
          let contentfulQuery = {};
          switch (item.fields.subNavigation) {
            case "Activity categories":
              contentfulQuery = {
                content_type: "category",
                "fields.type[match]": "Activity category",
                limit: 20,
                include: 2,
                order: "fields.name",
              };
              break;
            case "Accommodation categories":
              contentfulQuery = {
                content_type: "category",
                "fields.type[match]": "Accommodation category",
                limit: 20,
                include: 2,
                order: "fields.name",
              };
              break;
            case "Itineraries":
              contentfulQuery = {
                content_type: "itinerary",
                limit: 20,
                include: 0,
              };
              break;
            case "Question categories":
              contentfulQuery = {
                content_type: "questionCategory",
                limit: 20,
                include: 2,
                order: "fields.sortOrder",
              };
              break;
            default:
              contentfulQuery = {};
          }
          const cats = await getCachedQuery<ICategory>(
            contentfulQuery,
            this.props.i18n.language
          );
          if (cats) {
            const dataCats = cats.items.filter((el, j) => {
              if (
                el.fields.showSubmenu === undefined ||
                el.fields.showSubmenu === true
              ) {
                return true;
              } else return false;
            });
            // @ts-ignore
            tree[mainSlug] = dataCats.map((el: any, j: number) => {
              return el.fields.slug;
            });
          }
        }
      });
      return tree;
    }
  }

  public addFavourite(value: string, url?: string) {
    const localFavourites = window.localStorage.getItem("favourites");
    customGA("event", "favorite_add", {
      sys_id: value,
      url: url ? url : "not set",
    });

    if (!localFavourites) {
      const val = JSON.stringify([value]);
      window.localStorage.setItem("favourites", val);
    } else {
      const localVal = JSON.parse(localFavourites).includes(value)
        ? JSON.parse(localFavourites)
        : [...JSON.parse(localFavourites), value];
      window.localStorage.setItem("favourites", JSON.stringify(localVal));
    }
    const newFavourites = [...this.state.appContext.favouritesList, value];
    setTimeout(
      () =>
        this.setState({
          appContext: {
            ...this.state.appContext,
            favouritesList: newFavourites,
          },
        }),
      1
    );
  }

  public removeFavourite(value: string) {
    const localFavourites = window.localStorage.getItem("favourites");
    if (localFavourites) {
      const localVal = JSON.parse(localFavourites).filter(
        (item: string) => item !== value
      );
      window.localStorage.setItem("favourites", JSON.stringify(localVal));
    }
    setTimeout(
      () =>
        this.setState({
          appContext: {
            ...this.state.appContext,
            favouritesList: this.state.appContext.favouritesList.filter(
              (item: string) => item !== value
            ),
          },
        }),
      1
    );
  }

  public setFavourites(value: boolean) {
    setTimeout(
      () =>
        this.setState({
          appContext: {
            ...this.state.appContext,
            isFavouritesOpen: value,
          },
        }),
      1
    );
  }

  public setHasAskedForGdprConsent(value: boolean) {
    setTimeout(
      () =>
        this.setState({
          appContext: {
            ...this.state.appContext,
            hasAskedForGdprConsent: value,
          },
        }),
      1
    );
  }

  public setHasGdprConsent(value: boolean) {
    setTimeout(() => {
      this.setState({
        appContext: {
          ...this.state.appContext,
          hasGdprConsent: value,
        },
      });
      if (value) {
        customGA("consent", "update", {
          ad_user_data: "granted",
          ad_personalization: "granted",
          ad_storage: "granted",
          analytics_storage: "granted",
        });
      } else {
        customGA("consent", "update", {
          ad_user_data: "denied",
          ad_personalization: "denied",
          ad_storage: "denied",
          analytics_storage: "granted",
        });
      }
    }, 1);
  }

  public scrollToTop() {
    this.props.history.listen(() => {
      window.scrollTo(0, 0);
    });
  }

  public async getBrowser() {
    const theBrowser = await import(
      "../../splitting/browserslist-useragent-matchesua"
    );
    const isIE = theBrowser.checkThat();
    return isIE;
  }

  public async componentDidMount() {
    const tree = await this.getTreeNavigation();
    this.setState({
      appContext: {
        ...this.state.appContext,
        treeNavigation: tree ? tree : [],
      },
    });
    this.scrollToTop();
    if (this.props.location.pathname !== "/") {
      ReactGA.pageview(this.props.location.pathname);
      /* customGA("event", "page_view", {
				page_path: this.props.location.pathname,
				page_location: document.location,
				anonymize_ip: true,
				cleanPath: this.props.location.pathname,
			}); */
      if (this.state.appContext.hasGdprConsent) {
        customGA("consent", "update", {
          ad_user_data: "granted",
          ad_personalization: "granted",
          ad_storage: "granted",
          analytics_storage: "granted",
        });
      }
    }
    // eslint-disable-next-line
    const ieTest = this.getBrowser().then((theBrowser) => {
      this.setState({ isIE: theBrowser });
    });
  }

  public componentDidUpdate(prevProps: any) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({
        appContext: {
          ...this.state.appContext,
          referrerUrl: prevProps.location.pathname,
        },
        pathName: this.props.location.pathname,
      });
      this.scrollToTop();
      this.getSiteConfig(this.props.i18n.language);
      /* if (this.props.location.pathname !== "/") {
        ReactGA.pageview(this.props.location.pathname);
				customGA("event", "page_view", {
					page_path: this.props.location.pathname,
					page_location: document.location,
					page_referrer:
						"https://www.curacao.com" + prevProps.location.pathname,
					anonymize_ip: true,
					cleanPath: this.props.location.pathname,
				});
      } */
    }
  }

  public render() {
    if (this.state.isIE === "isIE") {
      return (
        <AppContext.Provider value={this.state.appContext}>
          <Switch>
            <Route
              exact
              path="/:locale/upgrade-browser"
              component={AsyncUpgradeBrowser}
            />
            <Redirect to={`/${this.props.i18n.language}/upgrade-browser`} />
          </Switch>
        </AppContext.Provider>
      );
    } else if (this.state.isIE === "isNotIE") {
      return (
        <AppContext.Provider value={this.state.appContext}>
          <Navigation
            history={this.props.history}
            activeLocation={this.props.location.pathname}
            location={this.props.location}
          />
          <div className="root">
            <Switch>
              <Redirect exact path="/" to={`/${this.props.i18n.language}/`} />
              {/* <Redirect exact path="/:locale" to="/:locale/" /> */}
              <Route
                exact
                path="/:locale/category/:slug"
                component={AsyncCategory}
              />
              <Route
                exact
                path="/:locale/activity/:slug"
                component={AsyncItem}
              />
              <Route
                exact
                path="/:locale/itinerary/:slug"
                component={AsyncItinerary}
              />
              <Route exact path="/:locale/saved" component={AsyncSaved} />
              <Route
                exact
                path="/:locale/article/:slug"
                component={AsyncArticle}
              />
              <Route
                exact
                path="/:locale/accommodation/:slug"
                component={AsyncItem}
              />
              <Route exact path="/:locale/event/:slug" component={AsyncItem} />
              <Route
                path="/:locale/questions/search"
                component={AsyncQuestionSearch}
              />
              <Route
                exact
                path="/:locale/questions/:slug"
                component={AsyncQuestionContainer}
              />
              <Route
                exact
                path="/:locale/questions/:category/:slug"
                component={AsyncQuestionContainer}
              />
              <Route
                exact
                path="/:locale/interactive/:slug"
                component={AsyncInteractiveMap}
              />
              <Route
                exact
                path="/:locale/interactive"
                component={AsyncInteractiveMap}
              />
              <Route
                exact
                path="/:locale/interactive/:category/:slug"
                component={AsyncInteractiveMap}
              />
              <Route exact path="/:locale/404" component={AsyncNotFound} />
              <Route exact path="/:locale/:slug" component={AsyncPage} />
              <Route
                exact
                path="/:locale/:slug/page/:pagenr"
                component={AsyncPage}
              />
              <Route exact path="/:locale/" component={AsyncPage} />
            </Switch>
          </div>
          <Footer history={this.props.history} />
          <GdprConsent
            history={this.props.history}
            location={this.props.location}
          />
          <Favourites />
          <WhatsappChat />
          <GoogleTagManager
            gtmId="GTM-5VLWKQ5"
            gdprConsent={this.state.appContext.hasGdprConsent}
            pathName={this.state.pathName}
          />
        </AppContext.Provider>
      );
    } else {
      return (
        <div className="root">
          <Loader />
        </div>
      );
    }
  }
}

export default withTranslation()(Root);
