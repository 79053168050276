import * as React from "react";
import "./multiButtonLink.style.css";

interface IMultiButtonLinkProps {
  onClick?: any;
  text?: string;
  className?: string;
  to: string;
  type?: string;
}

const types = new Map([
  ["basic", "button--basic"],
  ["important", "button--important"],
  ["important-alt", "button--important-alt"],
  ["card", "button--ed-card"],
  ["light", "button--light"],
]);

const MultiButtonLink = (props: IMultiButtonLinkProps) => {
  const {
    text = `${props.type ? props.type : types.values().next().value} Button`,
    onClick,
    className,
    to,
    type,
  } = props;
  const values = {
    onClick,
    className: `
    ${(type && types.get(type)) || types.values().next().value} 
    ${className ? className : ""}`,
    href: to,
    target: "_blank",
    rel: "noopener",
  };

  return (
    <a {...values}>
      <h6>{text}</h6>
    </a>
  );
};

export default MultiButtonLink;
