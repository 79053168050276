import * as React from "react";
import "./tickListItem.style.css";

interface IProps {
  className?: string;
  onClick?: any;
  text: string;
  id: string;
  ticked?: boolean;
}

const TickListItem = (props: IProps) => {
  const { className = "", onClick, text, ticked = false, id } = props;
  return (
    <li
      className={`tickListItem
        ${className}`}
    >
      <label className={`tickListItem__label ${ticked ? "-checked" : ""}`}>
        {text}
        <input
          id={id}
          onChange={onClick}
          className="tickListItem__checkbox"
          type="checkbox"
          checked={ticked}
          aria-checked={ticked}
        />
      </label>
    </li>
  );
};

export default TickListItem;
