import * as React from "react";
import "./loader.style.css";

interface IProps {
  className?: string;
}

const Loader = (props: IProps) => {
  const { className = "" } = props;
  return (
    <svg
      className={`loaderSvg ${className}`}
      width="100%"
      height="100%"
      viewBox="0 0 137 139"
    >
      {/* tslint:disable:max-line-length */}
      <g>
        <path
          id="loading-c"
          className="loaderSvg__point loaderSvg__0"
          d="M65.2,6.7c-1.2,2.7-1.6,4.8-2.6,7.4c-0.1,5.3-2.5,12.4,3.6,15.4c0.8-0.3,1.5,0.1,2.3,0.4
		c0.9,0.4,1.7,0.7,2.6,0.2c0.5,0,1,0,1.6,0.1c1.5,0.2,3,0.3,4-1.2c0.8-1.3,0.9-3.1,1.7-4.8c0.9-6.5-0.5-12.6-2.4-18.9
		c-1.8-0.5-1.9-2.6-3.3-3.1c-0.5-0.6-1.1-1-1.7-1.4C68.2,2.2,66.9,4.6,65.2,6.7z"
        />
      </g>
      <g>
        <path
          id="loading-a"
          className="loaderSvg__point loaderSvg__1"
          d="M100,26.4c-1.2,1.3-2.4,2.5-3.7,3.7c-1.1,2.3-2.8,4.7-2.1,7.2c-0.6,1.2,0.1,2.3,0.1,3.4
		c1.6,0.6,1.3,1.9,2.9,2.5c3.4,1.7,5.9-2.3,8.8-3.7c4.3-4.7,7.7-10.3,10.1-16.1c-0.5-2-1.5-3.9-3.5-4.4
		C108.8,21.3,104.2,24.2,100,26.4z"
        />
      </g>
      <path
        className="loaderSvg__point loaderSvg__2"
        d="M110.8,49.6c-1.5,3.5-0.8,7.5,1.2,9.9c5.4,3.7,12-1.4,16.9-4.5c0.8-1.2,3.9-1.1,3.2-3.3
	c1.2-1.2,2.7-2.5,2.9-4.1c0.6-0.5,0.8-1.5,0.8-2.2c-1.3-3.5-5.6-3.2-8.7-3.4C121.5,43.2,114.1,43.9,110.8,49.6z"
      />
      <g>
        <path
          id="loading-b"
          className="loaderSvg__point loaderSvg__3"
          d="M108.5,79.5l-1.6,2.1c0.2,0.1,0.6,0.1,0.7,0.4c0.1,0.6-0.6,0.6-1,0.9c1,1.5-1.5,2.7-0.2,4.2
		c0,1.2,0.8,1.5,2.1,1.8c2.2-0.5,4.4-0.2,6.7,0.1c1.5,0.2,3,0.4,4.5,0.3c3,0.2,6.1,0.5,9.1-0.2c0.6-1.1,2.4-2,2-3.3
		c0.7-0.1,0.6-0.8,0.8-1.2c0-0.4-0.8-1.5,0.5-1.8c-0.7-1.6-2.5-1.8-3.9-2.5c-3.3-1.9-7.4-3.5-11.6-3.5
		C113.8,77,111.1,77.7,108.5,79.5z"
        />
      </g>

      <g>
        <path
          id="loading-d"
          className="loaderSvg__point loaderSvg__4"
          d="M100.5,98.1c-1.5,0.5-2.4,1.2-3.9,2c0.3,1.1-1.1,0.7-1.7,1.3c-0.4,0.7,0.4,2.2-1.1,1.9
		c-0.2,1-0.9,1.8-0.8,2.7c5.6,5.3,9.4,13.9,17.9,15.6c0.7-0.3,1.6-0.3,2.4-0.2c1.4,0.1,2.7,0.1,3.6-1.5c2.3-4.3-0.6-8.4-2-12.5
		c-1.2-2.8-3.7-4-5.2-6.3c-2-1.7-4.9-3-7.7-3C101.5,98,101,98,100.5,98.1z"
        />
      </g>
      <g>
        <path
          id="loading-f"
          className="loaderSvg__point loaderSvg__5"
          d="M77.7,109.3c-0.5-0.4-0.9-0.1-1.2,0.3c-0.4,0.4-0.7,0.8-1.2,0c-0.7,0.4-1.2,0.5-1.8,1.1
		l-0.6-0.4c-0.1,0.9-1.6,0.7-1.4,1.7c-0.7-0.6-0.6,0.4-1.3,0.5c-0.7,5.7,1.3,10.8,1.7,16.3c0.5,3.2,2.5,6.6,5.1,8.5
		c1.2,0.2,2.4-0.4,3.7-0.9c1.2-0.5,2.4-1,3.7-0.9c3.2-1.3,1.3-4.4,1.8-6.6c-1.3-6.7,0.2-15-5.6-19.8c-0.5,0.4-1,0.2-1.4,0.1
		c-0.2-0.1-0.5-0.2-0.7-0.2C78.1,109,77.9,109.1,77.7,109.3z"
        />
      </g>
      <g>
        <path
          id="loading-h"
          className="loaderSvg__point loaderSvg__6"
          d="M44.2,103.2c-0.4,0.1-0.9,0.2-1.3,0.2c-3.5,1.7-4.9,5.4-7.1,8.1c-1.5,3.4-5.7,7.2-2.5,10.8
		c2.7,0.6,2.1,5.2,5.9,3.8c6.4-1.6,8.2-8.3,12-12.6c1.4-2.2,2-4.6,1.4-6.9c-1.3-0.8-2-3.6-4-2.2c-0.2-1.2-1.5-1.3-2.5-0.6
		c0.2-0.2,0-0.4-0.1-0.5c-0.3-0.1-0.5-0.2-0.8-0.2C44.9,103,44.5,103.1,44.2,103.2z"
        />
      </g>
      <g>
        <path
          id="loading-i"
          className="loaderSvg__point loaderSvg__7"
          d="M24.8,83.4c-4.6-0.2-8.7,3.7-12.8,5.6c-1.3,1.2-3.2,2.5-4.9,3.3c0.7,0.8-0.4,1.3-0.7,1.8
		c-1,0.9,0.2,2.5-0.4,3.7c0.9,1.6,1.6,3.5,3.3,4.1c6.2,0,12.8-0.4,19.1-1.6c2,0,2.8-1.9,4-3c0.5-1.5-0.7-2.7-0.3-4.2
		c1-1.6-0.3-3-0.6-4.8c-1.2-0.1-0.3-1.2-0.8-1.8c1.3-1.8-1.8-1.1-1.8-2.9c-0.6-0.5-1.4-0.7-2.1-0.7C26.2,83,25.4,83.2,24.8,83.4z"
        />
      </g>
      <g>
        <path
          id="loading-e"
          className="loaderSvg__point loaderSvg__9"
          d="M25,21.3c2.7,2-0.4,5.2,1.7,7.5c1.7,5.6,5,12.3,11.3,13.5c1.1,0.1,2.5,0.2,3.3-0.8
		c0.5,0.2,0.9-0.1,1.2-0.3c0.3-0.2,0.7-0.5,1.2-0.3c1.1-1.2,1.8-2.1,2.8-3.4c0.1-5.8-4.7-10.8-8.8-14l-2.5-3.7
		c-1.2-1.6-3.7-2.2-5.3-2.8C27.6,18.2,25.6,19.2,25,21.3z"
        />
      </g>

      <g>
        <path
          id="loading-g"
          className="loaderSvg__point loaderSvg__8"
          d="M4.4,52.4c-0.5-0.2-0.9-0.3-1.1,0.1L1,54c0,1.1,1.8,1.1,1.3,2.4l-0.9,0.5
		c0,2.3,1.7,3.8,3.2,5.4c3.9,4.4,10,6.8,16.2,7.7c3.2,0.3,6.3,0.2,9.3-0.9c-0.4-0.6,0.2-1.3,0.4-2l1-0.6l-1-1.3
		c0.1-0.6,0.8-0.4,1.2-0.5c0.1-0.8-1.8-2.3-0.3-3.2c-0.5-0.1-0.4-0.7-0.9-0.8c0.4-0.4-0.2-0.7-0.1-1.2c-7.9-3.4-16.4-5.9-24.7-7.6
		C5.6,52.8,5,52.6,4.4,52.4z"
        />
      </g>

      {/* tslint:enable:max-line-length */}
    </svg>
  );
};

export default Loader;
