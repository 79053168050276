import * as React from "react";
import { isMobile } from "react-device-detect";
import "./horizontalScroll.style.css";

interface IProps {
  className?: string;
  color?: "grey" | "white" | "mask";
  innerRef?: any;
  lock?: boolean;
  snapping?: boolean;
  fractions?: number;
  scrollBar?: any;
  defaultPosition?: number;
  disableScrollBar?: boolean;
}

interface IState {
  isLeft: boolean;
  isRight: boolean;
  percent: number;
  position: number;
}

class HorizontalScroll extends React.Component<
  React.PropsWithChildren<IProps>,
  IState
> {
  public state = {
    isLeft: true,
    isRight: false,
    percent: 0,
    position: 0,
  };

  public constructor(props: IProps) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
    this.scrollToPosition = this.scrollToPosition.bind(this);
  }

  public componentDidMount() {
    if (this.props.defaultPosition && this.props.innerRef) {
      this.scrollToPosition(this.props.defaultPosition);
      this.setState({
        position: this.props.defaultPosition,
      });
    }
  }

  public render() {
    const {
      children,
      className = "",
      color = "white",
      lock = false,
      snapping = false,
      scrollBar,
      // fractions,
      disableScrollBar = false,
    } = this.props;
    const refProp = this.props.innerRef
      ? {
          ref: this.props.innerRef,
        }
      : {};

    const awayFromMid =
      this.state.percent <= 0.5
        ? 1 - this.state.percent * 2
        : (this.state.percent - 0.5) * 2;

    const maskStyle =
      color === "mask"
        ? {
            WebkitMaskImage: `linear-gradient(
          to ${this.state.percent <= 0.5 ? "right" : "left"},
          rgba(0, 0, 0, 1) 90%,
          rgba(0, 0, 0, ${1 - awayFromMid}) 100%
        )`,
          }
        : {};
    return (
      <div
        className={`horizontalScroll ${
          color === "mask" ? "-mask" : ""
        } ${className}`}
        style={maskStyle}
      >
        <div
          className={`horizontalScroll__content-left-fade -${color}`}
          style={{
            opacity: isNaN(this.state.percent) ? 0 : this.state.percent,
          }}
        />
        <div className="horizontalScroll__scroll-wrapper">
          <div
            className={`horizontalScroll__content ${lock ? "-lock" : ""} ${
              snapping ? "-snapping" : ""
            }
            ${isMobile || disableScrollBar ? "-disable-scroll-bar" : ""}`}
            {...refProp}
            onScroll={this.handleScroll}
          >
            {children}
          </div>
        </div>
        <div
          className={`horizontalScroll__content-right-fade -${color}`}
          style={{
            opacity: 1 - this.state.percent,
          }}
        />
        {scrollBar &&
          scrollBar({
            position: this.state.position,
            scrollToPosition: this.scrollToPosition,
            percent: this.state.percent,
          })}
      </div>
    );
  }

  private handleScroll(event: any) {
    const scrollLeft = event.currentTarget.scrollLeft;
    const scrollRight =
      event.currentTarget.scrollWidth -
      event.currentTarget.scrollLeft -
      event.currentTarget.clientWidth;
    const scrollWidth = scrollLeft + scrollRight;
    const isLeft = scrollLeft === 0;
    const isRight = scrollRight === 0;
    const percent = scrollLeft / scrollWidth;

    let position = 0;

    if (this.props.fractions) {
      // Get position
      const scrollableWidth =
        event.currentTarget.scrollWidth - event.currentTarget.clientWidth;
      const scrollFraction = scrollableWidth / (this.props.fractions - 1);
      position = Math.round(scrollLeft / scrollFraction) + 1;
    }

    this.setState({
      isLeft,
      isRight,
      percent,
      position,
    });
  }

  private scrollToPosition(position: number) {
    if (!this.props.fractions && this.props.innerRef) {
      return false;
    }

    const elem = this.props.innerRef.current;

    const scrollableWidth = elem.scrollWidth - elem.clientWidth;
    const scrollFraction = scrollableWidth / ((this.props.fractions || 0) - 1);

    this.props.innerRef.current.scrollTo({
      left: (position - 1) * scrollFraction,
      behavour: "smooth",
    });
  }
}

export default HorizontalScroll;
