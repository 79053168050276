import * as React from "react";
import "./form.style.css";

interface IProps {
  className?: string;
  children?: any;
  onSubmit?: any;
}

const Form = (props: IProps) => {
  const { className = "", children, onSubmit } = props;
  return (
    <form
      className={`form ${className}`}
      onSubmit={(e: any) => {
        e.preventDefault();
        onSubmit(getFormData(e));
      }}
    >
      {children}
    </form>
  );
};

function getFormData(e: any) {
  const fields = Array.prototype.slice
    .call(e.target)
    .filter((el: any) => el.name)
    .reduce(
      (form: any, el: any) => ({
        ...form,
        [el.name]: el.value,
      }),
      {}
    );

  return fields;
}

export default Form;
