import * as React from "react";
import "./linkListItem.style.css";

interface IProps {
  className?: string;
  text: string;
  icon: any;
  link: string;
  openNew?: boolean;
  onClick?: () => void | undefined;
}

const LinkListItem = (props: IProps) => {
  const { className = "", openNew = false, text, icon, link, onClick } = props;
  return (
    <li
      className={`linkListItem
    ${className}`}
    >
      <img
        src={icon}
        alt={"Link list icon"}
        className="body--1 linkListItem__icon"
      />
      {link ? (
        <a
          href={link}
          target={openNew ? "_blank" : undefined}
          rel={openNew ? "noreferrer" : undefined}
          className={`linkListItem__label`}
        >
          {text}
        </a>
      ) : (
        <div onClick={onClick} role="button" className={`linkListItem__label`}>
          {text}
        </div>
      )}
    </li>
  );
};

export default LinkListItem;
