const customGA = (command: string, commandType: string, params?: object) => {
  // @ts-ignore
  if (window.customGtag) {
    if (params) {
      // @ts-ignore
      window.customGtag(command, commandType, params);
    } else {
      // @ts-ignore
      window.customGtag(command, commandType);
    }
  }
};

export default customGA;
