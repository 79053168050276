import { Entry } from "contentful";
import copy from "copy-to-clipboard";
import React from "react";
import { isMobile } from "react-device-detect";
import { withTranslation, WithTranslation } from "react-i18next";
import { MultiButton, RoundIconButton } from "../../components/Buttons";
import Card from "../../components/Card";
// import Container from "../../components/Container";
import { SlideUpDialogue } from "../../components/Dialogue";
import Form, { TextInput } from "../../components/Form";
import { SingleColumnList } from "../../components/List";
import { LocaleNavigationWrapper } from "../../components/LocaleNavigationLink/LocaleNavigationLink";
import { FavouritesSVG } from "../../components/SVG";
import {
  IAccommodation,
  IActivity,
  IEvent,
  IItinerary,
} from "../../contentfulTypes";
import { AppContext, IAppInterface } from "../../context";
import { getCachedQuery } from "../../util/contentfulQueries";
import DisableScroll from "../DisableScroll";
import HorizontalScroll from "../HorizontalScroll";
import EmptyCard from "./EmptyCard";
import "./favourites.style.css";

interface IProps extends WithTranslation {
  className?: string;
}

interface IState {
  items: Array<Entry<IActivity | IAccommodation | IEvent | IItinerary>>;
  filter: string[];
  favouritesList: string[];
  copyLink: boolean;
  copiedLink: boolean;
}

class Favourites extends React.Component<IProps, IState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;
  constructor(props: IProps) {
    super(props);
    this.state = {
      items: [],
      filter: [],
      favouritesList: [],
      copyLink: false,
      copiedLink: false,
    };
  }

  public componentDidMount() {
    this.getData();
  }

  public componentDidUpdate() {
    if (this.context.favouritesList !== this.state.favouritesList) {
      this.setState({ favouritesList: this.context.favouritesList });
      this.getData();
    }
  }

  public getData = async () => {
    const itemsData = await getCachedQuery<
      IActivity | IAccommodation | IEvent | IItinerary
    >(
      {
        "sys.id[in]": this.context.favouritesList.join(","),
      },
      this.props.i18n.language
    );

    if (itemsData) {
      this.setState({ items: itemsData.items });
    }
  };

  public render() {
    const { className = "" } = this.props;
    const scrollRef = React.createRef<HTMLDivElement>();
    // const [copyLink, setCopyLink] = React.useState(false);
    // const [copiedLink, setCopiedLink] = React.useState(false);
    // const [loadedContent, setLoadedContent] = React.useState([]);
    const i18n = this.props.i18n;

    const favourites = this.state.items;
    const hasFavourites = favourites.length > 0;

    const activityFavourites = favourites.filter(
      (item) => item.sys.contentType.sys.id === "activity"
    );

    const accommodationFavourites = favourites.filter(
      (item) => item.sys.contentType.sys.id === "accommodation"
    );

    const eventFavourites = favourites.filter(
      (item) => item.sys.contentType.sys.id === "event"
    );

    const itineraryFavourites = favourites.filter(
      (item) => item.sys.contentType.sys.id === "itinerary"
    );

    return (
      <AppContext.Consumer>
        {({
          isFavouritesOpen,
          setFavourites,
          favouritesList,
        }: IAppInterface) => (
          <div
            className={`favourites ${isFavouritesOpen ? "-open" : "-closed"} ${
              favouritesList.length > 0 ? "-has-content" : ""
            } ${className}`}
            ref={scrollRef}
          >
            <DisableScroll
              disable={isMobile ? false : isFavouritesOpen}
              allow={scrollRef.current}
            >
              <>
                <div className="h4 favourites__title">
                  {this.props.i18n.t("yourFavourites")}
                  <div
                    role="button"
                    className="favourites__share-desktop body--1"
                    onClick={() => {
                      this.setState({
                        copyLink: true,
                      });
                    }}
                  >
                    {i18n.t("share")}
                  </div>
                  <RoundIconButton
                    icon="cross"
                    className="favourites__close"
                    onClick={() => {
                      setFavourites(false);
                    }}
                  />
                </div>
                <div
                  role="button"
                  className="favourites__share-mobile body--1"
                  onClick={() => {
                    this.setState({
                      copyLink: true,
                    });
                  }}
                >
                  {i18n.t("share")}
                </div>
                {hasFavourites && (
                  <div className="favourites__before-content-wrapper">
                    <div className="favourites__before-content">
                      <MultiButton
                        className={`favourites__filter-btn ${
                          this.state.filter.includes("activity") &&
                          activityFavourites.length > 0
                            ? "-active"
                            : ""
                        } ${
                          activityFavourites.length === 0 ? "-disabled" : ""
                        }`}
                        type={
                          this.state.filter.includes("activity") &&
                          activityFavourites.length > 0
                            ? "basic"
                            : "light"
                        }
                        text={`${this.props.i18n.t("thingsToDo")} (${
                          activityFavourites.length
                        })`}
                        onClick={() => {
                          if (activityFavourites.length !== 0) {
                            this.setState({
                              filter: this.state.filter.includes("activity")
                                ? this.state.filter.filter(
                                    (item) => item !== "activity"
                                  )
                                : [...this.state.filter, "activity"],
                            });
                          }
                        }}
                      />
                      <MultiButton
                        className={`favourites__filter-btn ${
                          this.state.filter.includes("accommodation") &&
                          accommodationFavourites.length > 0
                            ? "-active"
                            : ""
                        } ${
                          accommodationFavourites.length === 0
                            ? "-disabled"
                            : ""
                        }`}
                        type={
                          this.state.filter.includes("accommodation") &&
                          accommodationFavourites.length > 0
                            ? "basic"
                            : "light"
                        }
                        text={`${this.props.i18n.t("accommodations")} (${
                          accommodationFavourites.length
                        })`}
                        onClick={() => {
                          if (accommodationFavourites.length !== 0) {
                            this.setState({
                              filter: this.state.filter.includes(
                                "accommodation"
                              )
                                ? this.state.filter.filter(
                                    (item) => item !== "accommodation"
                                  )
                                : [...this.state.filter, "accommodation"],
                            });
                          }
                        }}
                      />
                      <MultiButton
                        className={`favourites__filter-btn ${
                          this.state.filter.includes("event") &&
                          eventFavourites.length > 0
                            ? "-active"
                            : ""
                        } ${eventFavourites.length === 0 ? "-disabled" : ""}`}
                        type={
                          this.state.filter.includes("event") &&
                          eventFavourites.length > 0
                            ? "basic"
                            : "light"
                        }
                        text={`${this.props.i18n.t("events")} (${
                          eventFavourites.length
                        })`}
                        onClick={() => {
                          if (eventFavourites.length !== 0) {
                            this.setState({
                              filter: this.state.filter.includes("event")
                                ? this.state.filter.filter(
                                    (item) => item !== "event"
                                  )
                                : [...this.state.filter, "event"],
                            });
                          }
                        }}
                      />
                      <MultiButton
                        className={`favourites__filter-btn ${
                          this.state.filter.includes("itinerary") &&
                          itineraryFavourites.length > 0
                            ? "-active"
                            : ""
                        } ${
                          itineraryFavourites.length === 0 ? "-disabled" : ""
                        }`}
                        type={
                          this.state.filter.includes("itinerary") &&
                          itineraryFavourites.length > 0
                            ? "basic"
                            : "light"
                        }
                        text={`${this.props.i18n.t("itineraries")} (${
                          itineraryFavourites.length
                        })`}
                        onClick={() => {
                          if (itineraryFavourites.length !== 0) {
                            this.setState({
                              filter: this.state.filter.includes("itinerary")
                                ? this.state.filter.filter(
                                    (item) => item !== "itinerary"
                                  )
                                : [...this.state.filter, "itinerary"],
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                )}
                <HorizontalScroll
                  lock={favouritesList.length === 0}
                  color="mask"
                  className={`favourites__content ${
                    hasFavourites ? "" : "-no-filter-bar"
                  }`}
                >
                  {!hasFavourites ? (
                    <>
                      <EmptyCard image />
                      <div className="favourites__description">
                        {this.props.i18n.t("favouritesText2")}{" "}
                        <FavouritesSVG
                          // eslint-disable-next-line
                          style="disabled"
                          className="favourites__inline-heart"
                        />
                      </div>
                      <EmptyCard />
                      <EmptyCard />
                      <EmptyCard />
                      <EmptyCard />
                      <EmptyCard />
                      <EmptyCard />
                    </>
                  ) : (
                    favourites
                      .filter((item) => favouritesList.includes(item.sys.id))
                      .filter((item) =>
                        this.state.filter.length === 0 ||
                        this.state.filter.includes(item.sys.contentType.sys.id)
                          ? true
                          : false
                      )
                      .map((item, key: number) => {
                        const activityItem = item as Entry<IActivity>;
                        const itineraryItem = item as Entry<IItinerary>;
                        const title =
                          activityItem.fields.title ||
                          itineraryItem.fields.pageTitle;
                        return (
                          <Card
                            key={key}
                            square
                            favourite={item.sys.id}
                            className="favourites__card"
                            hoverBackground={false}
                            title={title}
                            onClick={() => {
                              setFavourites(false);
                            }}
                            onFavourite={(val) => {
                              const numberOfCat =
                                item.sys.contentType.sys.id === "activity"
                                  ? activityFavourites.length
                                  : item.sys.contentType.sys.id ===
                                    "accommodation"
                                  ? accommodationFavourites.length
                                  : item.sys.contentType.sys.id === "event"
                                  ? eventFavourites.length
                                  : item.sys.contentType.sys.id === "itinerary"
                                  ? itineraryFavourites.length
                                  : 0;
                              if (
                                this.state.filter.includes(
                                  item.sys.contentType.sys.id
                                ) &&
                                numberOfCat <= 1
                              ) {
                                this.setState({
                                  filter: this.state.filter.filter(
                                    (fil) => fil !== item.sys.contentType.sys.id
                                  ),
                                });
                              }
                            }}
                            to={`/${item.sys.contentType.sys.id}/${item.fields.slug}`}
                            image={item.fields.images[0]}
                          />
                        );
                      })
                  )}
                </HorizontalScroll>
                {favouritesList.length === 0 && (
                  <div className="favourites__after-content">
                    {this.props.i18n.t("favouritesText2")}{" "}
                    <FavouritesSVG
                      // eslint-disable-next-line
                      style="disabled"
                      className="favourites__inline-heart"
                    />
                  </div>
                )}
                <SlideUpDialogue
                  onClose={() => {
                    this.setState({
                      copyLink: false,
                    });
                  }}
                  title={i18n.t("shareLink")}
                  open={this.state.copyLink}
                >
                  <SingleColumnList>
                    <LocaleNavigationWrapper
                      to={`/saved?list=${favouritesList.join(",")}`}
                    >
                      {(link: string) => (
                        <>
                          <Form className="favourites__form">
                            <TextInput
                              className="favourites__input"
                              name="link"
                              value={`${window.location.host}${link}`}
                              onChange={() => {
                                return false;
                              }}
                            />
                          </Form>
                          <MultiButton
                            className={`favourites__copy-btn ${
                              this.state.copiedLink ? "-tick" : ""
                            }`}
                            type="basic"
                            text={i18n.t("copyLink")}
                            onClick={() => {
                              this.setState({
                                copiedLink: true,
                              });
                              setTimeout(() => {
                                this.setState({
                                  copiedLink: false,
                                });
                              }, 2000);
                              copy(`${window.location.host}${link}`);
                            }}
                          />
                        </>
                      )}
                    </LocaleNavigationWrapper>
                  </SingleColumnList>
                </SlideUpDialogue>
              </>
            </DisableScroll>
          </div>
        )}
      </AppContext.Consumer>
    );
  }
}

Favourites.contextType = AppContext;

export default withTranslation()(Favourites);
