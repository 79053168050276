import * as React from "react";
import { LoaderSVG } from "../SVG";
import "./loader.style.css";

interface IProps {
  isLoading?: boolean;
  error?: boolean;
}

const Loader = ({ isLoading, error }: IProps) => {
  if (isLoading) {
    return <LoaderSVG className="loader" />;
  }
  return null;
};

export default Loader;
