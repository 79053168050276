import { Entry } from "contentful";
import React, { useState } from "react";
import NavigationLink from "../../components/NavigationLink";
// import PictureWrapper from "../../components/PictureWrapper";
import { IInternalNavigationLink } from "../../contentfulTypes";
import NavigationSubMenu, { SubMenuType } from "./NavigationSubMenu";

interface IProps {
  items: Array<Entry<IInternalNavigationLink>>;
  onClick?: (event: any) => void;
}
/*
          style={{
            backgroundImage: `url("${
              link.fields &&
              link.fields.image &&
              link.fields.image.fields.file.url
            }?fit=fill&w=200&h=300&q=50")`,
          }}
					<div className="nav__item-gradient" />
          {link.fields.image && (
            <PictureWrapper
              className={`nav__item-img`}
              options={[["fit", "fill"]]}
              small={[200, 300]}
              medium={[200, 300]}
              large={false}
              asset={link.fields.image}
            />
          )}
					
					${!link.fields.subNavigation ? "has-right-arrow-mobile" : ""}
					
*/

const NavigationLeft = (props: IProps) => {
  const { items, onClick } = props;
  const mobileOnlyArray = items.map((itm) => !!itm.fields.mobileOnly);
  const firstNavItem = mobileOnlyArray.indexOf(false);
  const [showSub, setSub] = useState(-1);
  return (
    <ul className="nav__left">
      {items.map((link, i) => (
        <li
          className={`nav__item 
          ${link.fields.mobileOnly ? "-mobile-only" : ""}
            ${i === firstNavItem ? "-first" : ""}
						${showSub === i ? "show-submenu" : ""}
					`}
          key={i}
        >
          {(link.fields.subNavigation || link.fields.subnavigationLinks) && (
            <div
              className="has-right-arrow-mobile toggle-submenu"
              onClick={() => setSub(showSub === i ? -1 : i)}
            ></div>
          )}
          <NavigationLink
            key={i}
            link={link as unknown as Entry<IInternalNavigationLink>}
            onClick={onClick}
          />
          {link.fields.subNavigation && (
            <NavigationSubMenu
              type={link.fields.subNavigation as SubMenuType}
              active={showSub === i ? true : false}
              baseLink={link.fields.link.fields.slug}
              baseImage={link.fields.image}
              onClick={(e) => {
                setSub(-1);
                if (onClick !== undefined) {
                  onClick(e);
                }
              }}
            />
          )}
          {link.fields.subnavigationLinks && (
            <NavigationSubMenu
              type={"Custom links" as SubMenuType}
              active={showSub === i ? true : false}
              baseLink={link.fields.link.fields.slug}
              baseImage={link.fields.image}
              links={link.fields.subnavigationLinks}
              onClick={(e) => {
                setSub(-1);
                if (onClick !== undefined) {
                  onClick(e);
                }
              }}
            />
          )}
        </li>
      ))}
    </ul>
  );
};

export default NavigationLeft;
