import { Entry } from "contentful";
import {
  IItinerary,
  IPage,
  IQuestion,
  IQuestionCategory,
} from "../contentfulTypes";

const entryToLink = (data: Entry<any>, locale: string) => {
  switch (data.sys.contentType.sys.id) {
    case "page": {
      return getPageDetails(data, locale);
    }
    case "question": {
      return getQuestionDetails(data, locale);
    }
    case "questionCategory": {
      return getQuestionCategoryDetails(data, locale);
    }
    case "itinerary": {
      return getItineraryDetails(data, locale);
    }
    default: {
      return {
        link: `/${locale}/${data.sys.contentType.sys.id}/${data.fields.slug}`,
        title: data.fields.title || data.fields.name || "Error",
      };
    }
  }
};

const getPageDetails = (data: Entry<IPage>, locale: string) => ({
  link: `/${locale}/${data.fields.slug}`,
  title: data.fields.name,
});

const getQuestionCategoryDetails = (
  data: Entry<IQuestionCategory>,
  locale: string
) => ({
  link: `/${locale}/questions/${data.fields.slug}`,
  title: data.fields.name,
});

const getQuestionDetails = (data: Entry<IQuestion>, locale: string) => {
  return {
    link: `/${locale}/questions/${data.fields.category.fields.slug}/${data.fields.slug}`,
    title: data.fields.title,
  };
};

const getItineraryDetails = (data: Entry<IItinerary>, locale: string) => {
  return {
    link: `/${locale}/itinerary/${data.fields.slug}`,
    title: data.fields.pageTitle,
  };
};

export default entryToLink;
